import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import "./contactus.css";
import { useState, useEffect } from "react";
import { Button } from "primereact/button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import ThankYoU from "../../images/Thankyou.png";
import { Card } from "primereact/card";

function ContacusPage() {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const validateField = (field, value) => {
    let error = "";

    switch (field) {
      case "name":
        if (!value.trim()) error = "Name is required.";
        break;
      case "email":
        if (!value.trim()) error = "Email is required.";
        else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value))
          error = "Enter a valid email address.";
        break;
      case "phone":
        if (!value.trim()) error = "Phone number is required.";
        else if (!/^\+?[0-9]{10,15}$/.test(value))
          error = "Enter a valid phone number.";
        break;
      case "message":
        if (!value.trim()) error = "Message cannot be empty.";
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));

    return !error;
  };

  // Handle each field's validation individually
  const handleFieldChange = (field, value) => {
    // Set the field's value
    switch (field) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }

    validateField(field, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isFormValid =
      validateField("name", name) &
      validateField("email", email) &
      validateField("phone", phone) &
      validateField("message", message);

    if (isFormValid) {
      try {
        const formattedPhone = phone.startsWith("+") ? phone : `+${phone}`;

       
        const payload = {
          fullName: name,
          emailAddress: email,
          mobileNumber: formattedPhone,
          enquiringFrom: "India",
          message: message,
        };
     
        const response = await fetch("/saveContactUsEnquiry", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Form submitted successfully!",
          });

          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
          setErrors({});
          setOpenSuccessDialog(true);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to submit form. Please try again.",
          });
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An error occurred while submitting the form.",
        });
        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <>
      <div className="contactPage displyToastTop">
        <Toast ref={toast} />

        <Dialog
          visible={openSuccessDialog}
          onHide={() => {
            setOpenSuccessDialog(false);
          }}
          className="dbaAllDialogs">
          <div className="thankYouDialog">
            <img src={ThankYoU} alt="" />
            <div className="flex homeFlex justify-content-center ">
              <Button
                onClick={() => {
                  navigate("/");
                  setOpenSuccessDialog(false);
                }}
                className="w-full"
                rounded
                raised
                label="Close"></Button>
            </div>
          </div>
        </Dialog>

        <Card className="contact-container">
          <div className="contact-content homeFirstSection shadow-4">
            <div className="contact-info">
              <h3>Contact Information</h3>
              <p>
                Fill up the form and our team will get back to you within 24
                hours
              </p>

              <div className="info-item">
                <span role="img" aria-label="phone">
                  📞
                </span>{" "}
                <a href="tel:+919490384177">+91 9490384177</a>
              </div>
              <div className="info-item">
                <span role="img" aria-label="email">
                  ✉️
                </span>{" "}
                <a href="mailto:support@clotatechnology.com">
                  support@clotatechnology.com
                </a>
              </div>

              <div className="social-icons">
                <a
                  href="https://www.facebook.com/ClotaTech"
                  className="social-icon"
                  target="_blank"
                  rel="noopener noreferrer">
                  <FaFacebookF />
                </a>
                <a
                  href="https://www.instagram.com/ClotaTech"
                  className="social-icon"
                  target="_blank"
                  rel="noopener noreferrer">
                  <FaInstagram />
                </a>
                <a
                  href="https://www.linkedin.com/company/clota-tech/"
                  className="social-icon"
                  target="_blank"
                  rel="noopener noreferrer">
                  <FaLinkedinIn />
                </a>
              </div>
            </div>

            <div className="contact-form">
              <h3 className="header Text-Gradient">
                Any Question or remarks? Just write us a message
              </h3>
              <form onSubmit={handleSubmit}>
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Your FullName"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onInput={(e) => {
                    // Allow only alphabets and spaces
                    e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
                  }}
                />
                {errors.name && (
                  <span style={{ color: "red" }} className="error">
                    {errors.name}
                  </span>
                )}

                <label>Email</label>
                <input
                  type="email"
                  placeholder="john@email.com"
                  value={email}
                  onChange={(e) => handleFieldChange("email", e.target.value)}
                />
                {errors.email && (
                  <span style={{ color: "red" }} className="error">
                    {errors.email}
                  </span>
                )}

                <div>
                  <label>Phone</label>
                  <div style={{ display: "flex" }}>
                    <PhoneInput
                      country={"eg"}
                      enableSearch={true}
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                    />
                  </div>
                </div>
                {errors.phone && (
                  <span style={{ color: "red" }} className="error">
                    {errors.phone}
                  </span>
                )}

                <label>Message</label>
                <textarea
                  placeholder="Write your message"
                  value={message}
                  onChange={(e) => handleFieldChange("message", e.target.value)}
                />
                {errors.message && (
                  <span style={{ color: "red" }} className="error">
                    {errors.message}
                  </span>
                )}

                <Button
                  label="Send Message"
                  type="submit"
                  className="submit-button"
                  disabled={!name || !email || !message || !phone}
                />
              </form>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

export default ContacusPage;

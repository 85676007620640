import React, { useState, useEffect, useRef } from "react";
import TimezoneSelect from "react-timezone-select";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import DatePicker from "react-datepicker";
import "../Payments/Checkout.css";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import PhoneInput from "react-phone-input-2";
import ThankYoU from "../../../images/Thankyou.png";
import { Dialog } from "primereact/dialog";
import { BiLogoMicrosoftTeams } from "react-icons/bi";
import { IoMdTime } from "react-icons/io";

function FreeCall() {
  const toast = useRef(null);
  const [fullName, setFullName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [emailID, setEmailID] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [formattedDate, setFormattedDate] = useState("");
  const [formattedDateDdMMYyyy, setFormattedDateDdMMYyyy] = useState("");
  const [timezoneInfo, setTimezoneInfo] = useState("");
  const [timeSlots, setTimeSlots] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const today = new Date();
  const [timezone, setTimezone] = useState(null);

  const handleTimezoneChange = (selectedTimezone) => {
    setTimezone(selectedTimezone);
    setTimezoneInfo(selectedTimezone.label);
  };

  const fetchTimeSlots = async () => {
    try {
      const response = await fetch("/generalTimeSlots");
      if (!response.ok) {
        throw new Error("Failed to fetch time slots");
      }
      const data = await response.json();
      return data.slotTimes || [];
    } catch (error) {
      console.error("Error fetching time slots:", error);
      return [];
    }
  };

  const fetchScheduledCalls = async () => {
    try {
      const response = await fetch(
        `/scheduledCallsOnDate/${formattedDate}`,
        {}
      );
      const result = await response.json();
      return Array.isArray(result) ? result : [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const filterAvailableTimeSlots = (slotTimes, scheduledCalls) => {
    const currentTime = new Date();
    const currentTimeString =
      currentTime.getHours().toString().padStart(2, "0") +
      ":" +
      currentTime.getMinutes().toString().padStart(2, "0");

    const futureSlots = slotTimes.filter((time) => {
      const [hour, minute] = time.split(":").map((num) => parseInt(num, 10));
      const slotTime = new Date(currentTime);
      slotTime.setHours(hour, minute, 0, 0);
      return slotTime > currentTime;
    });

    const scheduledTimes = scheduledCalls.map((call) => call.callTime.trim());

    const availableSlots = futureSlots.filter(
      (time) => !scheduledTimes.includes(time.trim())
    );

    return availableSlots;
  };

  const fetchDataAndFilterSlots = async () => {
    const slotTimes = await fetchTimeSlots();
    const scheduledCalls = await fetchScheduledCalls();

    const availableSlots = filterAvailableTimeSlots(slotTimes, scheduledCalls);

    const formattedSlots = availableSlots.map((time) => ({
      label: time.slice(0, 5),
      value: time,
    }));

    setTimeSlots(formattedSlots);
  };

  useEffect(() => {
    fetchDataAndFilterSlots();
  }, [formattedDate]);

  const navigate = useNavigate();

  const handleDateChange = (date) => {
    setStartDate(date);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const formattedDDMMYYY = `${day}-${month}-${year}`;
    setFormattedDateDdMMYyyy(formattedDDMMYYY);

    const formatted = `${year}-${month}-${day}`;
    setFormattedDate(formatted);
  };

  const handleTimeSlotChange = (e) => {
    setSelectedTimeSlot(e.value);
  };

  const handleSubmitApi = async (e) => {
    e.preventDefault();

    const dateAndTime = new Date(`${formattedDate}T${selectedTimeSlot}`);
    const formattedPhone = phone.startsWith("+") ? phone : `+${phone}`;

    try {
      const response = await fetch("/savescheduledCalls", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify({
          fullName: fullName,
          emailAddress: emailID,
          mobileNumber: formattedPhone,
          callDateTime: dateAndTime.toISOString(),
          timeZone: timezoneInfo,
          message: message,
          callCategory: "Free",
          amount: 0,
        }),
      });

      // Check the response status
      if (response.ok) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Saved",
          life: 10000,
        });

        setFullName("");
        setMessage("");
        setEmailID("");
        setPhone("");
        setFormattedDate("");
        setSelectedTimeSlot("");
        setOpenDialog(true);
        setOpenConfirmDialog(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Some Error Occured",
          life: 5000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Some Error Occured",
        error,
        life: 5000,
      });
    }
  };

  return (
    <>
      <div className="flex justify-content-center align-content-center displyToastTop">
        <Toast ref={toast} />
        <Dialog
          visible={openDialog}
          onHide={() => {
            setOpenDialog(false);
          }}
          className="dbaAllDialogs">
          <div className="thankYouDialog">
            <img src={ThankYoU} alt="" />
            <div className="flex justify-content-center">
              <Button
                onClick={() => {
                  navigate("/");
                  setOpenDialog(false);
                  setSelectedTimeSlot("");
                  setFormattedDate("");
                }}
                className="w-full"
                rounded
                raised
                label="Close"></Button>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={openConfirmDialog}
          onHide={() => {
            setOpenConfirmDialog(false);
          }}
          style={{ width: "50vw" }}
          className="ConfirmDialog">
          <div className="grid">
            {/* <div className="col-12 lg:col-6">
            <div className="confirmImage">
              <img src={ThankYoU} width={100} className="mt-6 ml-2" />
            </div>
          </div> */}
            <div className="col-12 lg:col-12">
              <div className="confirmDialogForm">
                <h4 className="text-center">Confirm Your Details</h4>
                <table width="100%">
                  <thead>
                    <tr>
                      <td> FullName</td>
                      <td style={{ color: "#888888" }}> : {fullName} </td>
                    </tr>
                    <tr>
                      <td>EmailID</td>
                      <td style={{ color: "#888888" }}>: {emailID}</td>
                    </tr>
                    <tr>
                      <td>MobileNumber</td>
                      <td style={{ color: "#888888" }}>: {phone}</td>
                    </tr>
                    <tr>
                      <td>Date & Time</td>
                      <td style={{ color: "#888888" }}>
                        : {formattedDateDdMMYyyy} at {selectedTimeSlot}
                      </td>
                    </tr>
                    <tr>
                      <td>TimeZone</td>
                      <td style={{ color: "#888888" }}>: {timezoneInfo}</td>
                    </tr>
                    <tr>
                      <td>Message</td>
                      <td style={{ color: "#888888" }}>: {message}</td>
                    </tr>
                  </thead>
                </table>
                <div className="flex justify-content-between mt-3">
                  <div>
                    <p
                      className=""
                      style={{
                        marginTop: "-1px",
                        marginLeft: "5px",
                      }}>
                      <IoMdTime /> 30 Minutes
                    </p>
                  </div>

                  <div className="ml-3">
                    <p
                      className=""
                      style={{
                        marginTop: "-1px",
                        marginLeft: "5px",
                      }}>
                      <BiLogoMicrosoftTeams />
                      <span className="ml-1">
                        Meeting details provided to above EmailID.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex justify-content-center">
                  <Button
                    rounded
                    label="Edit or Cancel"
                    className="w-4"
                    outlined
                    severity="warning"
                    onClick={() => {
                      setOpenConfirmDialog(false);
                    }}
                  />
                  <Button
                    type="submit"
                    rounded
                    label="Confirm"
                    severity="success"
                    className="w-4 ml-3"
                    onClick={handleSubmitApi}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Card className="m-8">
          <div className="p-4" style={{ maxWidth: "600px" }}>
            <h4 className="text-center">Free 30 Minutes Call</h4>
            <div className="homeForm">
              <div className="homeForm flex mt-3">
                <div className="input-field">
                  <label className="label-line">Full Name</label>
                  <InputText
                    keyfilter={/^[a-zA-Z ]*$/}
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    // pattern=".{3,50}"
                    placeholder="Enter Your Name"
                    className=""
                  />
                </div>
                <div className="input-field">
                  <label className="label-line">EmailID</label>
                  <InputText
                    keyfilter="email"
                    value={emailID}
                    onChange={(e) => setEmailID(e.target.value)}
                    // pattern=".{3,50}"
                    placeholder="Enter EmailID"
                    className=""
                  />
                </div>
              </div>
              <div className="flex mt-3">
                <div className="input-field w-9">
                  {" "}
                  <label className="label-line">Mobile Number</label>
                  <PhoneInput
                    country={"eg"}
                    enableSearch={true}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  />
                </div>
                <div className="input-field ml-3">
                  {" "}
                  <label className="label-line">ShortMessage</label>
                  <InputText
                    keyfilter={/^[a-zA-Z ]*$/}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    // pattern=".{3,50}"
                    placeholder="Enter Message"
                    className=""
                  />
                </div>
              </div>
              <div className="flex mt-3">
                <div
                  className="custom-datePicker datepickerTd"
                  id="AttendanceParent">
                  {" "}
                  <label className="label-line">Pick Date And Time</label>
                  <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    inline
                    dateFormat="yyyy-MM-dd"
                    minDate={today}
                  />
                </div>
                <div className=" ml-3 mt-4">
                  <div className="mb-2">
                    <h2>{formattedDateDdMMYyyy}</h2>
                  </div>
                  <div className="mt-4">
                    <b>Time Zone</b>
                    <TimezoneSelect
                      value={timezone}
                      onChange={handleTimezoneChange}
                      placeholder="Select a Timezone"
                      className="mt-2 w-17rem"
                    />
                  </div>
                  <div className="mb-2">
                    <label className="mb-3 " style={{ fontWeight: "800" }}>
                      Select Time Slot
                    </label>
                    <Dropdown
                      id="floatingSelectGrid"
                      value={selectedTimeSlot}
                      options={timeSlots}
                      onChange={handleTimeSlotChange}
                      placeholder="Select Time Slot"
                      style={{ float: "right" }}
                      filter
                      // showClear
                      checkmark={true}
                      highlightOnSelect={true}
                      className="w-full mb-4 mt-3"
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-content-center m-2">
                <div className="">
                  <Button
                    label="ScheduleCall"
                    className=""
                    disabled={
                      !fullName ||
                      !emailID ||
                      !phone ||
                      !message ||
                      !timezone ||
                      !formattedDate ||
                      !selectedTimeSlot
                    }
                    onClick={() => {
                      setOpenConfirmDialog(true);
                    }}></Button>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

export default FreeCall;

import { Card } from "primereact/card";
import React from "react";
import HelloEms from "../../images/helloems.png";
import Clotatech from "../../images/ClotaHDLogoRound.png";
import { Button } from "primereact/button";
import NavBar from "../Navigation/NavBar";
import Footer from "../Navigation/Footer";

function OurProducts() {
  const handleNavigateHelloEms = () => {
    window.open("https://www.helloems.com", "_blank");
  };
  const handleNavigateClotatech = () => {
    window.open("https://www.clotatech.com", "_blank");
  };
  return (
    <>
      <div>
        <Card className="m-7">
          <h1 className="text-center text-900">Our Products</h1>
          <div className="grid">
            <div className="col-12 lg:col-6">
              <div className="p-3 h-full">
                <div
                  className="shadow-2 p-3 h-full flex flex-column"
                  style={{ borderRadius: "6px" }}>
                  <img
                    src={Clotatech}
                    alt=""
                    height={180}
                    className="shadow-3"
                  />
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />

                  <h4 className="text-center">
                    Your one-stop shop for digital solutions and marketing,
                    known for creativity, transparency, and strong work ethics
                  </h4>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                  <Button
                    label="Visit Now"
                    rounded
                    className="p-3 w-full mt-auto"
                    onClick={handleNavigateClotatech}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 lg:col-6">
              <div className="p-3 h-full">
                <div
                  className="shadow-2 p-3 h-full flex flex-column"
                  style={{ borderRadius: "6px" }}>
                  <img
                    src={HelloEms}
                    alt=""
                    height={150}
                    className="shadow-3"
                  />
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />

                  <h4 className="text-center">
                    HelloEMS a comprehensive Employee Management System designed
                    to streamline HR processes.
                  </h4>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                  <Button
                    label="Visit Now"
                    rounded
                    className="p-3 w-full mt-auto"
                    onClick={handleNavigateHelloEms}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

export default OurProducts;

import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./ClientDetails.css";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { CgAdd, CgAddR } from "react-icons/cg";
import AddClientImg from "../../../images/Add Client.png";
import { TabPanel, TabView } from "primereact/tabview";
import { Dropdown } from "primereact/dropdown";
import { TiUserAdd } from "react-icons/ti";
import { RiLoginBoxFill } from "react-icons/ri";

function ManageClients() {
  const toast = useRef();
  const [allClientDetails, setAllClientDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewLogins, setViewLogins] = useState(true);
  const [viewRoles, setViewRoles] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
   const [addIncidentOwners, setAddIncidentOwners] = useState(false);
   const [openServiceDialog, setOpenServiceDialog] = useState(false);
  const [formClientDetails, setFormClientDetails] = useState({
    clientname: "",
    clientEmailid: "",
    clientaddress: "",
    clientcontactNumber: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [error, setError] = useState("");

  async function fetchClientDetails() {
    const response = await fetch("/allClientDetails", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllClientDetails(result);
    } else {
      setAllClientDetails([]);
    }

    // setSelectedTimeslot(result.slotTimes[0]);
  }

  useEffect(() => {
    fetchClientDetails();
  }, []);

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        keyfilter={/^[a-zA-Z ]*$/}
        value={options.value}
        onChange={(e) =>
          options.editorCallback(e.target.value.substring(0, 150))
        }
      />
    );
  };
  const textEditorNormal = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) =>
          options.editorCallback(e.target.value.substring(0, 150))
        }
      />
    );
  };

  const textEditorContactNo = (options) => {
    const handleChange = (e) => {
      let newValue = e.target.value;
      newValue = "+" + newValue.slice(1).replace(/[^0-9]/g, "");
      options.editorCallback(newValue.substring(0, 150));
    };

    return (
      <InputText
        type="text"
        value={options.value}
        onChange={handleChange}
        maxLength={15}
      />
    );
  };

  const textEditorEmail = (options) => {
    const EmailEditChange = (e) => {
      const value = e.target.value;

      if (!emailRegex.test(value)) {
        setError("Invalid EmailID");
      } else {
        setError("");
      }
      options.editorCallback(value.substring(0, 150));
    };

    return (
      <>
        <InputText
          type="email"
          value={options.value}
          onChange={EmailEditChange}
          required
        />
        {error && (
          <p
            style={{ color: "red", fontSize: "10px" }}
            className="remaningChars">
            {error}
          </p>
        )}
      </>
    );
  };

  const onRowEditComplete = async (e) => {
    let _tasks = [...allClientDetails];
    let { newData, index } = e;

    _tasks[index] = newData;

    if (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Incorrect EmailID",
        life: 3000,
      });

      return;
    }

    setAllClientDetails(_tasks);

    try {
      const response = await fetch("/bulkUpdateClientDetails", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([newData]),
      });

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Client Details Updated successfully!",
          life: 3000,
        });
        // triggerApiDataChange();
        fetchClientDetails();
        setError("");
      }

      if (response.status !== 200) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to update data!",
          life: 3000,
        });
        // triggerApiDataChange();
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleInputChange = (name, value) => {
    setFormClientDetails((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputChangeNo = (name, value) => {
    let newValue = value;

    newValue = "+" + newValue.slice(1).replace(/[^0-9]/g, "");

    setFormClientDetails((prevFormData) => ({
      ...prevFormData,
      [name]: newValue.substring(0, 15),
    }));
  };

  const crearVariales = () => {
    setFormClientDetails({
      clientname: "",
      clientaddress: "",
      clientEmailid: "",
      clientContactNumber: "",
    });
  };



  const handleSubmitAddClients = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Proceed with adding the holiday
      const response = await fetch("/InsertBulkClientDetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([
          {
            clientName: formClientDetails.clientname,
            clientEmailID: formClientDetails.clientEmailid,
            clientAddress: formClientDetails.clientaddress,
            clientContactNumber: formClientDetails.clientcontactNumber,
          },
        ]),
      });
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Client Added successfully!",
          life: 3000,
        });
        crearVariales();
        setOpenDialog(false);
        fetchClientDetails();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error updating Client.",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error updating Holiday.",
        life: 3000,
      });
    }

    setIsLoading(false);
  };

   const addIncidentsOwners = () => {
     setAddIncidentOwners(true);
   };
   const addServiceCategory = () => {
     setAddIncidentOwners(false);
     setOpenServiceDialog(true);
   };


  const cities = [
    {
      name: "IncidentOwner",
      code: "IO",
      onclick: addIncidentsOwners,
      icon: <TiUserAdd />,
    },
    {
      name: "Service Category",
      code: "SC",
      onclick: addServiceCategory,
      icon: <RiLoginBoxFill />,
    },
  ];

  const dropDownIcons = (option) => {
    return (
      <div className="flex align-items-center">
        <div>
          {option.icon} {option.name}
        </div>
      </div>
    );
  };


    const placeholderTemplate = () => {
      return (
        <div className="p-clearfix" style={{ color: "white" }}>
          <CgAddR style={{ marginRight: "5px", fontSize: "1.2em" }} />
          <span style={{ fontSize: "1em", fontWeight: "bold", color: "white" }}>
            Add
          </span>
        </div>
      );
    };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        visible={openDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setOpenDialog(false);
          crearVariales();
        }}
        className="dbaAllDialogs">
        <Card className="shadow-5 AddHolidayyform">
          <h3 className="text-center">Add Client</h3>
          <div className="grid">
            <div className="col-12  lg:col-6">
              <div className="addClientLeftImg">
                <img src={AddClientImg} alt="" width="100%" />
              </div>
            </div>
            <div className="col-12 lg:col-6">
              <form onSubmit={handleSubmitAddClients}>
                <div className="flex flex-column gap-2 mb-3">
                  <label htmlFor="Holiday Name" className="font-bold block">
                    Client Name{" "}
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    value={formClientDetails.clientname}
                    keyfilter={/^[a-zA-Z\s]+$/}
                    onChange={(e) => {
                      handleInputChange(
                        "clientname",
                        e.target.value.substring(0, 25)
                      );
                    }}
                  />
                </div>
                <div className="flex flex-wrap gap-3 p-fluid">
                  <label htmlFor="buttondisplay" className="font-bold block">
                    Client EmailID
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>

                  <InputText
                    aria-describedby="username-help"
                    value={formClientDetails.clientEmailid}
                    type="email"
                    // className="datemobileFile"
                    onChange={(e) => {
                      handleInputChange(
                        "clientEmailid",
                        e.target.value.substring(0, 150)
                      );
                    }}
                  />
                </div>
                <div className="flex flex-column gap-2 mb-3">
                  <label htmlFor="Day" className="font-bold block">
                    Client Address{" "}
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    value={formClientDetails.clientaddress}
                    onChange={(e) => {
                      handleInputChange(
                        "clientaddress",
                        e.target.value.substring(0, 150)
                      );
                    }}
                  />
                </div>
                <div className="flex flex-column gap-2 mb-3">
                  <label htmlFor="Day" className="font-bold block">
                    Contact Number
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    value={formClientDetails.clientcontactNumber}
                    onChange={(e) => {
                      handleInputChangeNo(
                        "clientcontactNumber",
                        e.target.value
                      );
                    }}
                  />
                </div>
                <div className="flex justify-content-center mt-3">
                  <Button
                    type="submit"
                    label="Submit"
                    loading={isLoading}
                    disabled={
                      !formClientDetails.clientname ||
                      !formClientDetails.clientEmailid ||
                      !formClientDetails.clientaddress ||
                      !formClientDetails.clientcontactNumber
                    }
                    rounded
                    raised
                    className="w-full roundad"
                  />
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Dialog>
      <Card className="m-4">
        <TabView>
          <TabPanel header="Clients">
            <div className="firstdivClients">
              <h2 className="">Client Details</h2>
              <div className="m-3">
                <Button
                  raised
                  rounded
                  style={{ backgroundColor: "#0055cc" }}
                  onClick={() => {
                    setOpenDialog(true);
                  }}>
                  Add <CgAdd className="ml-2" />
                </Button>
              </div>
            </div>
            <Divider />
            <DataTable
              tableStyle={{ minWidth: "50rem" }}
              value={allClientDetails}
              size="small"
              paginator
              editMode="row"
              dataKey="clientID"
              onRowEditComplete={onRowEditComplete}
              rows={10}
              showGridlines
              rowsPerPageOptions={[10, 25, 50, 100]}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              removableSort
              scrollable
              scrollHeight="250px"
              className="FilterTable allDataTableThCenterCommon m-2"
              emptyMessage="No Data found.">
              <Column
                header="ClientID"
                field="clientID"
                frozen
                filter
                className="allTableTDCenterCommon"
              />
              <Column
                header="ClientName"
                field="clientName"
                className="allTableTDCenterCommon"
                sortable
                filter
                editor={(options) => textEditor(options)}></Column>
              <Column
                header="ClientEmailID"
                field="clientEmailID"
                className="allTableTDCenterCommon"
                sortable
                filter
                editor={(options) => textEditorEmail(options)}></Column>
              <Column
                header="ClientAddress"
                field="clientAddress"
                className="allTableTDCenterCommon"
                sortable
                filter
                editor={(options) => textEditorNormal(options)}></Column>
              <Column
                header="ClientContactNumber"
                field="clientContactNumber"
                className="allTableTDCenterCommon"
                sortable
                filter
                editor={(options) => textEditorContactNo(options)}></Column>
              <Column rowEditor></Column>
            </DataTable>
          </TabPanel>
          <TabPanel header="Logins">
            <Card>
              <div className="flex justify-content-between">
                <div>
                  <Button
                    label="Logins"
                    rounded
                    onClick={() => {
                      setViewLogins(true);
                      setViewRoles(false);
                    }}
                    className="mb-3"></Button>
                  <Button
                    label="Roles"
                    rounded
                    onClick={() => {
                      setViewLogins(false);
                      setViewRoles(true);
                    }}
                    className="ml-3 mb-3"></Button>
                </div>

                <div>
                  <Dropdown
                    value={selectedOption}
                    onChange={(e) => {
                      setSelectedOption(e.value);
                      if (e.value && e.value.onclick) {
                        e.value.onclick();
                      }
                    }}
                    options={cities}
                    optionLabel="name"
                    placeholder={placeholderTemplate()}
                    className="w-full"
                    itemTemplate={dropDownIcons}
                    style={{ backgroundColor: "#0055cc", color: "white" }}
                    rounded
                  />
                </div>
              </div>

              {viewLogins && (
                <DataTable>
                  <Column header="ID" />
                  <Column header="UserName" />
                  <Column header="Password" />
                  <Column header="Role" />
                  <Column />
                </DataTable>
              )}
              {viewRoles && (
                <DataTable>
                  <Column header="UserName" />
                  <Column header="Role" />
                  <Column />
                </DataTable>
              )}
            </Card>
          </TabPanel>
        </TabView>
      </Card>
    </div>
  );
}

export default ManageClients;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

function SuccessTransation() {
  const location = useLocation();
  const [paymentDetails, setPaymentDetails] = useState(null);
  useEffect(() => {
    // Extract the transaction ID from the URL
    const params = new URLSearchParams(location.search);
    const transactionID = params.get("transactionID");

    // Call backend to get payment details if needed
    axios
      .get(`/payment-details/${transactionID}`)
      .then((response) => {
        setPaymentDetails(response.data); // Set the payment details (if any)
      })
      .catch((error) => {
        console.error("Error fetching payment details", error);
      });
  }, [location]);
  return (
    <div>
      <h2>Payment Successful</h2>
      <p>
        Transaction ID:{" "}
        {paymentDetails ? paymentDetails.transactionID : "Loading..."}
      </p>
      <p>Amount: {paymentDetails ? paymentDetails.amount : "Loading..."}</p>
    </div>
  );
}
function FailedTransation() {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  useEffect(() => {
    // Extract the error message from URL
    const params = new URLSearchParams(location.search);
    const error = params.get("error");
    setErrorMessage(error || "Unknown error occurred.");
  }, [location]);
  return (
    <div>
      <h2>Payment Failed</h2>
      <p>{errorMessage}</p>
    </div>
  );
}
function PendingTransation() {
  return <div>PendingTransation</div>;
}
function InternalServerError() {
  return <div>InternalServerError</div>;
}

export {
  SuccessTransation,
  FailedTransation,
  PendingTransation,
  InternalServerError,
};

import React from "react";
import "./Checkout.css";


function CheckoutPage() {
  return (
    <>
      <div className="container">
        <div className="card cart">
          <label className="title">CHECKOUT</label>
          <div className="steps">
            <div className="step">
              <div>
                <span>Plan Details:</span>
                <p>Standard Plan</p>
                <p>Price $119 per hour</p>
              </div>
              {/* <Divider/> */}
              {/* <div>
                <span>PAYMENT METHOD</span>
                <p>Visa</p>
                <p>**** **** **** 4243</p>
              </div> */}
              {/* <hr />
              <div className="promo">
                <span>HAVE A PROMO CODE?</span>
                <form className="form">
                  <input
                    type="text"
                    placeholder="Enter a Promo Code"
                    className="input_field"
                  />
                  <button>Apply</button>
                </form>
              </div> */}
              <hr />
              <div className="payments">
                <span>PAYMENT</span>
                <div className="details">
                  <span>Subtotal:</span>
                  <span>$119.00</span>
                  <span>Shipping:</span>
                  <span>$0.00</span>
                  <span>Tax:</span>
                  <span>$10.40</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card checkout">
          <div className="footer">
            <label className="price">$129.40</label>
            <button className="checkout-btn">Checkout</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CheckoutPage;

import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import { Link, Outlet } from "react-router-dom";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { BsFillShieldLockFill } from "react-icons/bs";
import { IoMdAnalytics, IoMdClose } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdAddCall, MdTipsAndUpdates } from "react-icons/md";
import Logo911 from "../../images/logo 911.png";
import Footer from "./Footer";

function NavBar() {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select an option");
  const [navOpen, setNavOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const openDropdown = (e) => {
    setIsOpen(true);
  };
  const closeDropdown = (e) => {
    setIsOpen(false);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    closeDropdown();
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const location = useLocation();
  const currentPath = location.pathname;
  const pathToMatch = "/Contact";
  const shouldAddClass = currentPath === pathToMatch;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  function handleClick() {
    setNavOpen(!navOpen);
  }
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/login");
    setNavOpen(false);
  };

  return (
    <>
      <nav>
        <Link to="/">
          <img className="my-icon" src={Logo911} alt="" />
        </Link>
        <ul ref={dropdownRef} className={navOpen ? "openNav" : ""}>
          <li className="nav-items">
            <NavLink
              activeClassName="active"
              exact
              to="/"
              onClick={() => {
                setNavOpen(false);
              }}>
              Home
            </NavLink>
          </li>
          <li className="dropdownparent">
            <div
              id="services"
              style={{ cursor: "pointer" }}
              onMouseEnter={openDropdown}
              onMouseLeave={closeDropdown}>
              Resources ▼
            </div>
            {isOpen && (
              <div
                className="dropdown-content"
                onMouseEnter={openDropdown}
                onMouseLeave={closeDropdown}>
                <Link
                  to="/SqlServerTips"
                  className="dd-links"
                  onClick={() => {
                    handleOptionClick("Option 1");
                    setNavOpen(false);
                  }}>
                  <div className="icon-div">
                    <MdTipsAndUpdates />
                  </div>
                  SQL Tips
                </Link>
                <Link
                  to="/OurProducts"
                  className="dd-links"
                  onClick={() => {
                    handleOptionClick("Option 2");
                    setNavOpen(false);
                  }}>
                  <div className="icon-div">
                    <BsFillShieldLockFill />
                  </div>
                  Our Products
                </Link>
              </div>
            )}
          </li>
          <li className="nav-items">
            <NavLink
              to="/PricingPage"
              exact
              onClick={() => {
                setNavOpen(false);
              }}>
              Pricing
            </NavLink>
          </li>
          <li className="nav-items">
            <NavLink
              to="/aboutus"
              exact
              onClick={() => {
                setNavOpen(false);
              }}>
              About Us
            </NavLink>
          </li>
          <li className="nav-items">
            <NavLink
              to="/contactus"
              onClick={() => {
                setNavOpen(false);
              }}>
              Contact
            </NavLink>{" "}
          </li>
        </ul>
        <div className="contact-section">
          <button
            onClick={handleNavigation}
            className={`btn ${shouldAddClass ? "border-right" : ""}`}>
            <div className="contact-icon">
              <MdAddCall />
            </div>
            <div className="contact-text">Sign-In</div>
          </button>

          {isDialogOpen && (
            <div className="dialog-overlay" onClick={handleDialogClose}>
              <div className="dialog-box" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={handleDialogClose}>
                  ×
                </button>
                <div className="dialog-content">
                  <MdAddCall style={{ fontSize: "2rem", color: "#4CAF50" }} />
                  <p
                    style={{
                      margin: "10px 0",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}>
                    +91 9490384177
                  </p>
                  <a href="tel:+919490384177" className="call-button">
                    Dial Now
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="hamburger" onClick={handleClick}>
          {navOpen ? <IoMdClose /> : <RxHamburgerMenu />}
        </div>
      </nav>
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
}

export default NavBar;

import React from "react";
import NavBar from "../Navigation/NavBar";
import NewImg from "../../images/About Us Banner Two.png";
import UnmatchedExpertise from "../../images/Unmatched Expertise.png";
import TopLevelSecurity from "../../images/Top Level security.png";
import ContinuousServerOptimization from "../../images/sql server services.png";
import EmergencySupport from "../../images/Emergency support.png";
import "./About.css";
import Footer from "../Navigation/Footer";

function AboutPage() {
  return (
    <div className="DA-Hero">
      <div className="da-container">
        <div className="da-left">
          <h1> About Us</h1>
          <p>
            Utilize our cutting-edge analytics services to unleash the potential
            of data and gain practical insights for wise decision-making
          </p>
        </div>
        <div className="da-right">
          <img src={NewImg} alt="" />
        </div>
        <div className="da-bottom">
          <h2>
            It's Our Passion For Database Technology Which Lead Us Here.
          </h2>
          <p>
            Database Services ensure database security, performance, and
            reliability, focusing on configuration, uptime, and
            cost-effectiveness. We do Entire Life Cycle Support from database
            development to DBA support.We are expert in implementing database
            security, best practices and performance tuning.Expert in Cloud DB
            Migrating and implementation of high availability. Custom Database
            monitoring and alerting as per need.
          </p>
        </div>

        <div className="da-features">
          <h4>Why We Do Better Than Anyone Else.!</h4>
          <div className="da-features-card da-card1 ourSolutionCrd-1">
            <img src={UnmatchedExpertise} alt="" />
            <h5>Unmatched Expertise</h5>
            <p>
              Our Senior DBAs bring over 15 years of SQL Server management
              experience, covering strategy, architecture, development, and
              maintenance. With in-depth knowledge and hands-on skills, we offer
              tailored solutions to meet your unique database needs, ensuring
              precision and reliability in every partnership.
            </p>
          </div>
          <div className="da-features-card da-card2 ourSolutionCrd-1">
            <img src={TopLevelSecurity} alt="" />
            <h5>Top-Level Security</h5>
            <p>
              We prioritize your data security by implementing bank-level
              protocols to protect against unauthorized access and cyber
              threats. Our measures include encryption, access controls, and
              continuous monitoring, ensuring your SQL Server environment
              remains robust and resilient.
            </p>
          </div>
          <div className="da-features-card da-card3 ourSolutionCrd-1">
            <img src={ContinuousServerOptimization} alt="" />
            <h5>Continuous Server Optimization</h5>
            <p>
              We proactively monitor and optimize your SQL Server to maximize
              speed, efficiency, and reliability. Our monthly fine-tuning and
              detailed reporting highlight measurable improvements, ensuring
              your system performs at its best.
            </p>
          </div>
          <div className="da-features-card da-card4 ourSolutionCrd-1">
            <img src={EmergencySupport} alt="" />
            <h5> Emergency Support</h5>
            <p>
              Our 24/7 emergency support service provides quick assistance from
              experienced Senior DBAs to minimize downtime and restore normal
              operations during crises. Whether facing performance issues or
              data corruption, our team acts swiftly to keep your business
              running smoothly.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;

import React from "react";
import "./TermsAndConditions.css";


const TermsAndConditions = () => {
  return (
    <>
      <div className="terms-conditions">
        <h1>Terms and Conditions for Emergency Support Service</h1>

        <section>
          <h2>1. Scope of Service</h2>

          <h3>A) Emergency Support</h3>
          <p>
            In the event of a severe database issue, such as server crashes,
            corruption, or unforeseen security concerns, our Emergency Support offers a prompt, efficient solution. The emphasis
            is on identifying and fixing pressing problems that interfere with
            the operation of your database or the accessibility of your data.
          </p>

          <h3>B) Service Restrictions</h3>
          <p>
            Regular maintenance activities (such as upgrades or optimizations)
            and non-urgent problems that may wait are not covered by this
            emergency help. Contracts or other agreements may be needed for
            additional non-emergency services.
          </p>
        </section>

        <section>
          <h2>2. Service Availability and Response Time</h2>

          <h3>A) 24/7 Support Availability</h3>
          <p>
            In order to address emergencies, our staff is on call
            around-the-clock. Our goal is to start the issue evaluation process
            as soon as possible, usually within one to two hours after receiving
            an emergency request.
          </p>

          <h3>B) Response Time Commitment</h3>
          <p>
            Although we make every effort to respond quickly, the actual time
            may differ depending on the complexity and type of the problem as
            well as outside variables like network problems or reliance on
            external software.
          </p>
        </section>

        <section>
          <h2>3. Client Responsibilities</h2>

          <h3>A) Accurate Information</h3>
          <p>
            You must give us thorough, accurate information about the issue in
            order for us to address it successfully. This contains explanations
            of any error messages, recent modifications, and the acts that
            preceded the problem.
          </p>

          <h3>B) System Access</h3>
          <p>
            We might require administrative rights and network information to
            access your server environment in order to troubleshoot and fix
            it. The success and speed of the settlement process can be greatly
            impacted by ensuring timely access.
          </p>

          <h3>C) Data Backup</h3>
          <p>
            Since emergencies can pose a risk of data loss, we recommend that
            you maintain regular, up-to-date backups of your data. Although we
            will work diligently to prevent any data loss, 911DBA.com is not
            responsible for any loss of data that may occur during emergency
            interventions.
          </p>
        </section>

        <section>
          <h2>4. Commitment to Privacy</h2>
          <p>
            We recognize how crucial the security and privacy of your data are.
            Our staff adheres to established data protection regulations to
            safeguard your sensitive information during the assistance process,
            and any information and data you submit will be handled with the
            utmost confidentiality.
          </p>
        </section>

        <section>
          <h2>5. Limitation of Liability</h2>

          <h3>A) Scope of Responsibility</h3>
          <p>
            911DBA.com will do everything in our power to promptly and
            efficiently handle your emergency, but we are not responsible for
            problems that are out of our control, such as hardware malfunctions,
            incompatibilities with third-party applications, or external network
            disruptions that affect resolution.
          </p>

          <h3>B) Maximum Liability</h3>
          <p>
            The entire amount of fees paid for the particular occurrence is the
            maximum amount of damage we may be liable for as a result of
            emergency interventions. This restriction is meant to strike a
            compromise between the unpredictable nature of some technological
            challenges and our dedication to support.
          </p>
        </section>

        <section>
          <h2>6. Termination of Service</h2>

          <h3>A) Service Termination Rights</h3>
          <p>
            Either party may terminate emergency support once the issue is
            resolved or if it is determined that continuation of support is not
            feasible. For example, if a third-party vendor’s intervention is
            needed to fully resolve an issue, we may recommend redirecting
            further support to that party.
          </p>

          <h3>B) Payment Obligations upon Termination</h3>
          <p>
            In cases where the support service is terminated mid-process, you
            are responsible for paying for any hours worked or resources used up
            to that point. We will provide you with a detailed breakdown of the
            work completed and associated costs.
          </p>
        </section>

        <section>
          <h2>7. Modifications to Terms and Conditions</h2>

          <h3>A) Updates and Notifications</h3>
          <p>
            911DBA.com reserves the right to modify these Terms and Conditions
            as necessary to reflect updates in our service offerings, legal
            requirements, or best practices. Any changes will be communicated to
            you in advance, and the updated Terms and Conditions will become
            effective as specified in the notice.
          </p>

          <h3>B) Advance Notice</h3>
          <p>
            If significant changes are made, we will notify you in advance
            through your registered contact information, such as email or our
            client portal. The notice will provide details about the specific
            changes, the reasons for them, and how they might impact your
            engagement with us.
          </p>

          <h3>C) Effective Date of Changes</h3>
          <p>
            Unless otherwise specified, any changes to these Terms and
            Conditions will become effective on the date specified in the
            notice. Your continued use of our services following the effective
            date constitutes acceptance of the revised terms.
          </p>

          <h3>D) Client Review and Acknowledgment</h3>
          <p>
            We encourage you to review these Terms and Conditions periodically
            and to consult with us if you have any questions or concerns about
            any changes. Should you disagree with the revised terms, you have
            the option to discontinue services before the changes take effect,
            subject to any existing contractual obligations.
          </p>

          <h3>E) Record of Changes</h3>
          <p>
            For your convenience and transparency, we maintain a record of
            updates to our Terms and Conditions, which is accessible upon
            request. This log includes previous versions of the terms, the dates
            of modifications, and a summary of significant changes for your
            reference.
          </p>
        </section>
      </div>
    </>
  );
};

export default TermsAndConditions;

import React, { useState } from "react";
import "./Footer.css";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

import { Dialog } from "primereact/dialog";

function Footer() {
  const Today = new Date();
  const CurrentYear = Today.getFullYear();
  const [openDialog, setOpenDialog] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [openLegalHelp, setOpenLegalHelp] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="Footer">
      <Dialog
        header="Header"
        visible={openDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!openDialog) return;
          setOpenDialog(false);
        }}
        className="dbaAllDialogs">
        <p className="m-0">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </Dialog>
      <div className="footer-abtUs">
        <ul>
          <li className="footer-point-headings">ABOUT US</li>
          <li className="footer-list-items">
            Over a decade of experience and implementing best solutions in
            Database Technologies and Web Development. It's our passion for
            technologies which lead us here.
            <Link to="/aboutus" className="link-footer">
              Read More{" "}
            </Link>
          </li>
          <li className="icons">
            <div className="social-icons">
              <a href="https://www.instagram.com/ClotaTech" target="_clota">
                <FaInstagram />
              </a>
            </div>
            <div className="social-icons">
              <a href="https://www.facebook.com/ClotaTech" target="_clota">
                <FaFacebookF />
              </a>
            </div>
            <div className="social-icons">
              <a href="https://twitter.com/ClotaTech" target="_clota">
                <FaTwitter />
              </a>
            </div>
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/company/clota-tech/"
                target="_clota">
                <FaLinkedin />
              </a>
            </div>
            <div className="social-icons">
              <a
                href="https://api.whatsapp.com/send?phone=919490384177&amp;text=Hi, i'm enquiriying through your Site"
                target="_clota">
                <FaWhatsapp />
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div className="footer-srvcs">
        <ul className="list-margin">
          <li className="footer-point-headings"> SERVICES</li>
          <li className="footer-list-items p-1">
            <Link className="hover-effect-links" to="/SqlServerTips">
              SQL Tips
            </Link>
          </li>
          <li className="footer-list-items p-1">
            <Link className="hover-effect-links" to="/OurProducts">
              Our Products
            </Link>
          </li>
        </ul>
      </div>{" "}
      <div className="footer-srvcs">
        <ul className="list-margin">
          <li className="footer-point-headings"> Help Center</li>
          <li className="footer-list-items p-1">
            <a
              className="hover-effect-links"
              onClick={() => {
                navigate("/TermsAndConditions");
              }}>
              Terms Conditions
            </a>
          </li>
          <li className="footer-list-items p-1">
            <a
              className="hover-effect-links"
              onClick={() => {
                navigate("/PrivacyPolicy");
              }}>
              Privacy Policy
            </a>
          </li>

          <li className="footer-list-items p-1">
            <a
              className="hover-effect-links"
              onClick={() => {
                navigate("/CancellationAndRefunds");
              }}>
              Cancellation And Refunds
            </a>
          </li>
          <li className="footer-list-items p-1">
            <a
              className="hover-effect-links"
              onClick={() => {
                navigate("/ShippingAndDelivery");
              }}>
              Shipping And Delivery
            </a>
          </li>
          <li className="footer-list-items p-1">
            <a
              className="hover-effect-links"
              // target="_blank"

              onClick={() => {
                setOpenDialog(false);
                setOpenTerms(false);
                setOpenHelp(false);
                setOpenLegalHelp(false);
                navigate("/contactus");
              }}>
              Help Center
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-cntctUs">
        <ul>
          <li className="footer-point-headings"> CONTACT US</li>{" "}
          <li className="footer-point-headings"> CLOTA TECHNOLOGY LLP</li>
          <li className="footer-list-items">
            {" "}
            401 Plot # 103, Shilpa Paradise,{" "}
          </li>
          <li className="footer-list-items">Pragathi Nagar Hyderabad, </li>
          <li className="footer-list-items">Telangana, India 500090</li>
          <li className="footer-list-items mt-2">
            <a className="hover-effect-links" href="tel:+91 9490384177">
              +91 9490384177,
            </a>
            <a className="hover-effect-links ml-2" href="tel:+91 9573706658">
              +91 9573706658
            </a>
          </li>
          <li className="footer-list-items mt-2">
            <a
              className="hover-effect-links"
              href="mailto:support@clotatechnology.com">
              support@clotatechnology.com
            </a>
          </li>
        </ul>
      </div>
      {/* Social Icons Section (Last Element) */}
      <div className="footer-social-icons">
        <div className="social-icons">
          <a href="https://www.instagram.com/ClotaTech" target="_clota">
            <FaInstagram />
          </a>
        </div>
        <div className="social-icons">
          <a href="https://www.facebook.com/ClotaTech" target="_clota">
            <FaFacebookF />
          </a>
        </div>
        <div className="social-icons">
          <a href="https://twitter.com/ClotaTech" target="_clota">
            <FaTwitter />
          </a>
        </div>
        <div className="social-icons">
          <a
            href="https://www.linkedin.com/company/clota-tech/"
            target="_clota">
            <FaLinkedin />
          </a>
        </div>
        <div className="social-icons">
          <a
            href="https://api.whatsapp.com/send?phone=919490384177"
            target="_clota">
            <FaWhatsapp />
          </a>
        </div>
      </div>
      <div className="copyright">
        <Link className="link-footer" to="/">
          911dba
        </Link>{" "}
        911DBA Copyright © {CurrentYear} All rights reserved. Powered by{" "}
        <a
          className="link-footer"
          target="_blank"
          href="https://Clotatech.com"
          style={{ color: "white", textDecoration: "none", fontWeight: "700" }}>
          CLOTA TECHNOLOGY LLP
        </a>
      </div>
    </div>
  );
}

export default Footer;

import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import "./Managecalls.css";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { TabPanel, TabView } from "primereact/tabview";

function ManageCalls() {
  const [clientDetails, setClientDetails] = useState("");
  const [viewAllCalls, setViewAllCalls] = useState(true);
  const [viewRemainingCalls, setViewRemainingCalls] = useState(false);
  const [allRemainingCalls, setAllRemainingCalls] = useState([]);
  const [allCallDetails, setAllCallDetails] = useState([]);
  const [viewGeneralSlots, setViewGeneralSlots] = useState(true);
  const [viewCustomSlots, setViewCustomSlots] = useState(false);
  const [allGeneralSlots, setAllGeneralSlots] = useState([]);
const [selectedButton, setSelectedButton] = useState(null);

  async function fetchAllGeneralSlots() {
    const response = await fetch("/allGeneralTimeSlots", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllGeneralSlots(result);
    } else {
      setAllGeneralSlots([]);
    }
  }

  async function fetchAllCallDetails() {
    const response = await fetch("/allCalls", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllCallDetails(result);
    } else {
      setAllCallDetails([]);
    }
  }

  async function fetchAllFreeCallDetails() {
    setAllRemainingCalls([]);
    const response = await fetch("/allFreeCalls", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllRemainingCalls(result);
    } else {
      setAllRemainingCalls([]);
    }
  }

  async function fetchStandardCallDetails() {
    setAllRemainingCalls([]);
    const response = await fetch("/allStandardCalls", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllRemainingCalls(result);
    } else {
      setAllRemainingCalls([]);
    }
  }

  async function fetchPremiumCallDetails() {
    const response = await fetch("/allPremiumCalls", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllRemainingCalls(result);
    } else {
      setAllRemainingCalls([]);
    }
  }

  useEffect(() => {
    fetchAllCallDetails();
    fetchAllGeneralSlots();
  }, []);

   const handleButtonClick = (buttonName) => {
     setSelectedButton(buttonName);

     // Fetch data based on the button clicked
     switch (buttonName) {
       case "All Calls":
         setViewAllCalls(true);
         fetchAllCallDetails();
         setViewRemainingCalls(false);
         break;
       case "Free Calls":
         setViewAllCalls(false);
         setViewRemainingCalls(true);
         fetchAllFreeCallDetails();
         break;
       case "Standard Calls":
         setViewAllCalls(false);
         setViewRemainingCalls(true);
         fetchStandardCallDetails();
         break;
       case "Premium Calls":
         setViewAllCalls(false);
         setViewRemainingCalls(true);
         fetchPremiumCallDetails();
         break;
       default:
         break;
     }
   };

  return (
    <div>
      <Card className="m-5">
        <TabView>
          <TabPanel header="Manage Calls">
            <div className="callsButtonContainer">
              <Button
                label="All Calls"
                rounded
                outlined
                severity="help"
                className={
                  selectedButton === "All Calls" ? "selected-button" : ""
                }
                onClick={() => handleButtonClick("All Calls")}
              />
              <Button
                label="Free Calls"
                rounded
                outlined
                severity="Info"
                className={
                  selectedButton === "Free Calls" ? "selected-button" : ""
                }
                onClick={() => handleButtonClick("Free Calls")}
              />
              <Button
                label="Standard Calls"
                rounded
                outlined
                severity="success"
                className={
                  selectedButton === "Standard Calls" ? "selected-button" : ""
                }
                onClick={() => handleButtonClick("Standard Calls")}
              />
              <Button
                label="Premium Calls"
                rounded
                outlined
                severity="warning"
                className={
                  selectedButton === "Premium Calls" ? "selected-button" : ""
                }
                onClick={() => handleButtonClick("Premium Calls")}
              />
            </div>
            <Divider />
            {viewAllCalls && (
              <DataTable
                tableStyle={{ minWidth: "50rem" }}
                value={allCallDetails}
                size="small"
                paginator
                rows={10}
                showGridlines
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                removableSort
                scrollable
                scrollHeight="250px"
                className="FilterTable allDataTableThCenterCommon m-2"
                emptyMessage="No Data found.">
                <Column
                  header="ClientName"
                  field="fullName"
                  frozen
                  filter
                  className="allTableTDCenterCommon"
                />
                <Column
                  header="EmailID"
                  field="emailAddress"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                />
                <Column
                  header="ContactNo"
                  field="mobileNumber"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                />
                <Column
                  header="Category"
                  field="callCategory"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                />
                <Column
                  header="CallDateTime"
                  field="CallDateTime"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                />
                <Column
                  header="TimeZone"
                  field="timeZone"
                  className="allTableTDCenterCommon"
                />
                <Column
                  header="Amount"
                  field="amount"
                  className="allTableTDCenterCommon"
                />
                <Column
                  header="Message"
                  field="message"
                  className="allTableTDCenterCommon"
                />
              </DataTable>
            )}
            {viewRemainingCalls && (
              <>
                <DataTable
                  tableStyle={{ minWidth: "50rem" }}
                  value={allRemainingCalls}
                  size="small"
                  paginator
                  rows={10}
                  showGridlines
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  removableSort
                  scrollable
                  scrollHeight="250px"
                  className="FilterTable allDataTableThCenterCommon m-2"
                  emptyMessage="No Data found.">
                  <Column
                    header="ClientName"
                    field="fullName"
                    frozen
                    filter
                    className="allTableTDCenterCommon"
                  />
                  <Column
                    header="EmailID"
                    field="emailAddress"
                    className="allTableTDCenterCommon"
                    sortable
                    filter
                  />
                  <Column
                    header="ContactNo"
                    field="mobileNumber"
                    className="allTableTDCenterCommon"
                    sortable
                    filter
                  />
                  <Column
                    header="Category"
                    field="callCategory"
                    className="allTableTDCenterCommon"
                    sortable
                    filter
                  />
                  <Column
                    header="CallDateTime"
                    field="CallDateTime"
                    className="allTableTDCenterCommon"
                    sortable
                    filter
                  />
                  <Column
                    header="TimeZone"
                    field="timeZone"
                    className="allTableTDCenterCommon"
                  />
                  <Column
                    header="Amount"
                    field="amount"
                    className="allTableTDCenterCommon"
                  />
                  <Column
                    header="Message"
                    field="message"
                    className="allTableTDCenterCommon"
                  />
                </DataTable>
              </>
            )}
          </TabPanel>
          <TabPanel header="TimeSlots">
            <div className="callsButtonContainer">
              <Button
                label="Genaral TimeSlots"
                rounded
                onClick={() => {
                  setViewAllCalls(true);
                  setAllRemainingCalls(false);
                  setViewGeneralSlots(true);
                  setViewCustomSlots(false);
                  fetchAllGeneralSlots();
                }}
              />
              <Button
                label="Custom Timeslots"
                rounded
                onClick={() => {
                  setViewAllCalls(true);
                  setAllRemainingCalls(false);
                  setViewGeneralSlots(false);
                  setViewCustomSlots(true);
                }}
              />
            </div>
            <Divider />
            {allGeneralSlots && (
              <DataTable
                tableStyle={{ minWidth: "50rem" }}
                value={allGeneralSlots}
                size="small"
                paginator
                rows={10}
                showGridlines
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                removableSort
                scrollable
                scrollHeight="250px"
                className="FilterTable allDataTableThCenterCommon m-2"
                emptyMessage="No Data found.">
                <Column
                  header="ID"
                  field="id"
                  frozen
                  filter
                  className="allTableTDCenterCommon"
                />
                <Column
                  header="Category"
                  field="slotCategory"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                />
                <Column
                  header="Time"
                  field="slotTime"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                />
                <Column
                  header="Availability"
                  field="availability"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                />
              </DataTable>
            )}
            {viewCustomSlots && <></>}
          </TabPanel>
        </TabView>
      </Card>
    </div>
  );
}

export default ManageCalls;

import React, { useState } from "react";

const PhonepayTesting = () => {
  const [amount, setAmount] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create the payment request payload
    const dynamicTransactionId = `T${Date.now()}`;
    const payload = {
      transactionId: dynamicTransactionId,
      amount: parseFloat(amount),
      customerPhone: customerPhone,
    };

    try {
      // Send the payment request to the backend (where signature is generated)
      const response = await fetch("/api/payment/initiate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setResponseMessage(
        "Payment initiation successful. Response: " + JSON.stringify(data)
      );
    } catch (error) {
      setResponseMessage("Error: " + error.message);
    }
  };

  return (
    <div>
      <h1>Payment Form</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Amount:
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Customer Phone:
            <input
              type="text"
              value={customerPhone}
              onChange={(e) => setCustomerPhone(e.target.value)}
              required
            />
          </label>
        </div>
        <button type="submit">Initiate Payment</button>
      </form>

      {responseMessage && (
        <div>
          <h3>Response</h3>
          <pre>{responseMessage}</pre>
        </div>
      )}
    </div>
  );
};
export default PhonepayTesting;

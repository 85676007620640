import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import session from "../../images/CustomerImages/session.png";
import "./TimeOut.css";
import "./TimeoutPopUp.css";

const TimeOutPopUp = forwardRef((props, ref) => {
  // const [id, setId] = useState("");
  const uniqueID = sessionStorage.getItem("employeeID");
  const jwtToken = sessionStorage.getItem("jwtToken");
  const navigate = useNavigate();

  const [state, setStateUpdate] = useState(0);

  useImperativeHandle(ref, () => ({
    childFunction() {
      setTimeout(() => {
        setStateUpdate(state + 1);
        document
          .getElementsByClassName("sessionExpiryModel")[0]
          .parentElement.classList.add("TestDialogue");
      }, 500);
    },
  }));

  // useEffect(() => {
  //   const fetchEmployeeLogoutData = async () => {
  //     try {
  //       const employeeResponse = await fetch(`/loginLogoutTime/${employeeID}`, {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
  //         },
  //       });
  //       const employee = await employeeResponse.json();
  //       const { id, ...empData } = employee[0];
  //       setId(id);

  //       setEmployeeData(empData);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   fetchEmployeeLogoutData();
  // }, [employeeID]);

  const onLogOffButtonClick = () => {
    handleLogout();
  };

  const handleLogout = async () => {
    props.callParent();

    navigate("/login", { replace: true });
  };

  return (
    <div>
      <Dialog
        key={props.isOpen}
        closable={false}
        visible={props.isOpen}
        className={"sessionExpiryModel"}>
        <div class="modal-content">
          <div class="modal-header">
            <h2>Session Timeout</h2>
          </div>
          <div className="center-image">
            <img
              src={session}
              style={{ marginTop: "10px" }}
              height="100px"
              alt=""
            />
          </div>
          <div class="modal-body">
            <p>
              Your session is about to expire. For your security, you will be
              logged out automatically.
            </p>
            <p>Please save your work to avoid losing any data.</p>
          </div>
          <div class="modal-footer">
            <p>
              <strong>About 911DBA</strong>
            </p>
            <p>Transforming Data into Actionable Insights with SQL Expertise</p>
            <Button
              label="Login"
              raised
              rounded
              style={{ width: "150px" }}
              onClick={onLogOffButtonClick}
              className="sessiontimeout"></Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
});

export default TimeOutPopUp;

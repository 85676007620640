import React from "react";
import "./PrivacyPolicy.css";
import { Link } from "react-router-dom";
import logo from "../../images/logo 911.png";
import { Card } from "primereact/card";

const PrivacyPolicy = () => {
  return (
    <Card>
      <div className="privacy-policy">
        <h1>Privacy Policy</h1>
        <p className="mt-3 mb-4">
          At 911DBA we are committed to protecting your privacy and ensuring
          that your personal information is handled in a safe and responsible
          manner. This Privacy Policy describes the types of personal
          information we collect, how we use and protect it, and your rights
          regarding your information when using our website (911DBA) and our
          database-related services we offer
        </p>
        <p className="">Effective from December 5, 2024</p>
        <p>
          By accessing or using our Site or Services, you agree to the terms of
          this Privacy Policy. If you do not agree with the practices described
          herein, please do not use our Site or Services.
        </p>

        <section>
          <h2>1. Information We Collect</h2>
          <p>
            We collect various types of information in connection with the
            Services we provide:
          </p>

          <h3>1.1 Personal Information</h3>
          <p>
            Personal information refers to information that can identify you as
            an individual. The types of personal information we may collect
            include:
          </p>
          <ul>
            <li>
              <b>Contact Information:</b> Name, email address, phone number,
              company name (if applicable).
            </li>
            <li>
              <b>Billing Information:</b> Billing address, and other
              payment-related information(Only Mode of payment(Card/Cash/Wallet
              etc..)) when purchasing Services (note: payment processors may
              handle sensitive payment data).
            </li>
            <li>
              <b>Communication Data:</b> Any data you share with us through
              support tickets, email correspondence, live chat, or other means
              of communication.
            </li>
          </ul>

          <h3>1.2 Non-Personal Information</h3>
          <p>
            Non-personal information refers to data that does not identify you
            personally but may be used to improve our Services and your
            experience. This includes:
          </p>
          <ul>
            <li>
              <b>Usage Data:</b> Information about how you use our Site and
              Services, including your IP address, browser type, pages visited,
              and time spent on the Site.
            </li>
          </ul>
        </section>
        <section>
          <h2>2. How We Use Your Information</h2>
          <p>We use the information we collect for the following purposes:</p>
          <ul>
            <li>
              <b>Provide Services:</b> To deliver the database-related support
              services you request, including emergency support,
              troubleshooting, consulting, and other related services.
            </li>
            <li>
              <b>Account Management:</b> To create, manage, and maintain your
              user account and preferences.
            </li>
            <li>
              <b>Communication: </b>To communicate with you regarding your
              inquiries, support requests, updates, and service changes. We may
              also send promotional emails or updates about our Services if you
              opt in.
            </li>
            <li>
              <b>Billing and Payments:</b> To process payments for the Services
              you request and maintain accurate billing records.
            </li>
            <li>
              <b>Improve Services:</b> To analyze and improve our Site,
              Services, and overall user experience based on your interactions
              with the Site and feedback.
            </li>
            <li>
              <b>Security and Fraud Prevention:</b> To protect our Site and
              Services from misuse, unauthorized access, or fraudulent
              activities.
            </li>
          </ul>
        </section>
        <section>
          <h2>3. How We Protect Your Information</h2>
          <p>
            We are committed to safeguarding your personal information. We
            employ reasonable administrative, technical, and physical safeguards
            to protect your data from unauthorized access, use, or disclosure.
            However, no security measure is 100% secure, and we cannot guarantee
            the security of your data transmitted through the internet.
          </p>
          <h3>A. Data Retention</h3>
          <p>
            We will retain your personal information for as long as necessary to
            fulfill the purposes for which it was collected, comply with legal
            obligations, resolve disputes, and enforce our agreements.
          </p>
          <h3>B. Data Encryption</h3>
          <p>
            Sensitive information, such as name, email, phone number , address
            details, is encrypted using industry-standard security protocols
            during transmission and storage.
          </p>
        </section>
        <section>
          <h2>4. Sharing Your Information </h2>
          <p>
            We will not sell, rent, or trade your personal information to third
            parties. However, we may share your information in the following
            circumstances:
          </p>
          <ul>
            <li>
              <b>Service Providers:</b> We may share your personal information
              with third-party vendors or service providers who assist us in
              delivering our Services, such as payment processors, customer
              support platforms. These third parties are obligated to protect
              your data and use it only for the purposes we specify.
            </li>
            <li>
              <b>Legal Compliance:</b> We may disclose your information if
              required by law, regulation, or legal process, such as a subpoena
              or court order.
            </li>
            <li>
              <b>Business Transfers:</b> If 911DBA undergoes a merger,
              acquisition, or sale of assets, your personal information may be
              transferred as part of that transaction. You will be notified of
              any such change in ownership or control of your personal
              information.
            </li>
          </ul>
          <h2>5. Your Rights and Choices</h2>
          <p>
            Depending on your location and applicable laws, you may have certain
            rights regarding your personal data. These may include:
          </p>
          <ul>
            <li>
              <b>Access:</b> You may request access to the personal information
              we hold about you.
            </li>
            <li>
              <b>Correction:</b> You can request corrections to any inaccurate
              or incomplete personal information.
            </li>
            <li>
              <b>Deletion:</b> You can request the deletion of your personal
              information, subject to certain exceptions (e.g., legal
              obligations).
            </li>
            <li>
              <b>Opt-Out of Communications:</b> You may opt out of receiving
              marketing communications from us by following the unsubscribe
              instructions in our emails or by contacting us directly.
            </li>
            <li>
              <b>Data Portability:</b> In some cases, you may request a copy of
              your personal information in a structured, commonly used, and
              machine-readable format.
            </li>
          </ul>
          <p>
            To exercise these rights, please contact us at
            <a href="mailto:support@clotatechnology.com" className="ml-2">
              support@clotatechnology.com
            </a>
          </p>
          <h2>6. International Data Transfers</h2>
          <p>
            If you are accessing our Site from outside [Your Country], please
            note that your personal information may be transferred to and
            processed in countries that may have different data protection laws
            than those in your jurisdiction. By using our Site and Services, you
            consent to such transfers.
          </p>
          <h2>7. Children’s Privacy</h2>
          <p>
            Our Site and Services are not intended for use by individuals under
            the age of 18. We do not knowingly collect or solicit personal
            information from children. If we become aware that we have
            inadvertently collected personal information from a child, we will
            take steps to delete that information as soon as possible.
          </p>
          <h2>8. Changes to This Privacy Policy </h2>
          <p>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or for other operational, legal, or
            regulatory reasons. When we update the policy, we will post the
            revised version on this page with an updated "Effective Date." We
            encourage you to review this Privacy Policy periodically to stay
            informed about how we protect your personal data.
          </p>
        </section>
        <section className="">
          <h2>9. Contact Us </h2>
          <p>
            If you have any questions or concerns about this Privacy Policy, our
            data practices, or your personal data, please contact us at:
          </p>
          <div className="grid">
            <div className="col-12 lg:col-3">
              <div className="ml-7">
                <img
                  src={logo}
                  alt="Company Logo"
                  width={150}
                  className="footer-logo"
                />
              </div>
            </div>
            <div className="col-12 lg:col-9">
              <ul>
                <li>
                  <b>Email:</b>
                  <a href="mailto:support@clotatechnology.com ">
                    support@clotatechnology.com
                  </a>
                </li>
                <li>
                  <b>Mobile:</b>
                  <a href="tel:+91 9490384177 ">+91 9490384177 </a>
                </li>
                <li>
                  <b>Address:</b> Shilpa Paradise 6-154/Y/103-120/401, Gothic
                  Paranakuteer, Pragathi Nagar, KVRangareddy, Telangana ,India
                  -500090.
                </li>
              </ul>
            </div>
          </div>

          <p>Last updated: December 5, 2024</p>
        </section>
      </div>
    </Card>
  );
};

export default PrivacyPolicy;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css"; // core css
import "primereact/resources/primereact.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./commonCss/commoncsspage.css";

import "primereact/resources/themes/lara-light-cyan/theme.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();

import React from "react";
import "./CancellationAndRefunds.css";


const CancellationAndRefunds = () => {
  return (
    <>
      <div className="cancellation-refunds">
        <h1>Cancellation and Refunds for Emergency Support Service</h1>
        <p className="">Effective from December 5, 2024</p>
        <p>
          Thank you for choosing DBASupport for your database-related emergency
          support and services. We strive to provide exceptional service, and we
          want to make sure you understand our policies regarding cancellations,
          refunds, and service adjustments.
        </p>
        <p>
          By purchasing or using our Services, you agree to the terms outlined
          in this Cancellation and Refund Policy. If you have any questions or
          concerns, please do not hesitate to contact us.
        </p>

        <section>
          <h2>1. Cancellation of Services</h2>
          <p>
            We understand that plans can change. Below are the conditions under
            which you can cancel our services:
          </p>
          <h3>1.1 Emergency Database Support Services </h3>
          <ul>
            <li>
              <b>Immediate Cancellations:</b> Emergency support services are
              typically billed based on the time spent working on your issue. If
              you wish to cancel the support request, you must notify us before
              we begin work on your issue. If our team has already started
              working on your request, you will be billed for the time spent up
              until the cancellation.
            </li>
            <li>
              <b>Partial Cancellations:</b> If you cancel a service mid-session,
              you will be charged for the time already spent on troubleshooting,
              diagnosis, or repairs. You will not be billed for time spent after
              the cancellation notice.
            </li>
          </ul>
          <h3>1.2 Subscription-Based Services</h3>
          <p>
            For services offered on a recurring or subscription basis, such as
            ongoing database management, monitoring, or consulting:
          </p>
          <ul>
            <li>
              <b>Monthly Subscriptions:</b> You can cancel your subscription at
              any time before the start of the next billing cycle. Cancellations
              made after the billing date will take effect the following billing
              cycle.
            </li>
            <li>
              <b>Annual Subscriptions:</b> Cancellations made within 30 days of
              the annual renewal date may receive a pro-rated refund based on
              the unused portion of the service term. Cancellations made after
              30 days will not be eligible for a refund.
            </li>
          </ul>
          <h3>1.3 Custom Projects and Consulting </h3>
          <p>
            For customized database projects, such as setup, migration, or
            database optimization consulting, cancellations are subject to the
            following:
          </p>
          <ul>
            <li>
              <b>Pre-Work Cancellation:</b> If no work has started on your
              project, you may cancel and receive a full refund for any upfront
              payment.
            </li>
            <li>
              <b>Post-Work Cancellation:</b> If work has commenced, you will be
              billed for the work completed up to the point of cancellation. The
              remaining balance (if any) will be refunded.
            </li>
          </ul>

          <h2>2. Refund Policy</h2>
          <p>
            Our goal is to ensure that you are completely satisfied with the
            services we provide. We handle refunds based on the following
            conditions:
          </p>
          <h3>2.1 Eligibility for Refunds</h3>
          <p>
            Refunds are granted at our discretion, and eligibility will depend
            on the nature of the service and the circumstances of the request.
            The following scenarios may qualify for a refund:
          </p>
          <ul>
            <li>
              <b>Service Failure: </b>If we are unable to resolve your
              database-related issue after a reasonable amount of time, you may
              be eligible for a refund for services that were not successfully
              provided.
            </li>
            <li>
              <b>Non-Performance:</b> If we fail to deliver the agreed-upon
              services or timelines, you may be eligible for a refund for the
              portion of the service that was not completed as agreed.
            </li>
          </ul>
          <h3>2.2. Non-Refundable Services </h3>
          <p>The following services are non-refundable: </p>
          <ul>
            <li>
              <b>Consulting Services: </b>Once consulting services have been
              provided, even if partially, refunds will not be issued.
            </li>
            <li>
              <b>Emergency Support:</b> If we have already begun emergency
              database support or troubleshooting, no refund will be provided
              for the time spent addressing your issue.
            </li>
            <li>
              <b>Software or Tools:</b> Any software, licenses, or tools
              purchased on your behalf for your service, if applicable, are
              non-refundable.
            </li>
          </ul>
          <h3>2.3 Refund Process </h3>
          <p>
            To request a refund, please contact our support team at
            <a href="mailto:support@clotatechnology.com ">
              support@clotatechnology.com
            </a>
            within 30 days of the service. We will evaluate the request, and if
            eligible, we will process the refund within 7-10 business days to
            the original payment method.
          </p>
          <p>
            Refunds will not be provided for services already rendered or for
            services deemed to have been satisfactorily completed.
          </p>
          <h2>3. How to Request a Cancellation or Refund</h2>
          <p>
            To request a cancellation or refund, please follow the steps below:
          </p>
          <ul>
            <li>
              <b>Contact Us:</b> Reach out to our support team via email at
              <a href="mailto:support@clotatechnology.com ">
                support@clotatechnology.com
              </a>
              or by phone at <a href="tel:+91 9490384177 ">+91 9490384177 </a>
            </li>
            <li>
              <b>Provide Details:</b> Include your order details, the reason for
              cancellation, and any relevant information regarding your service.
            </li>
            <li>
              <b>Refund Evaluation:</b> Once we receive your request, we will
              review the service performed and your request to determine if a
              refund is applicable.
            </li>
            <li>
              <b>Confirmation:</b> We will send you a confirmation email
              detailing the cancellation and refund (if applicable).
            </li>
          </ul>

          <h2>4. Service Adjustments</h2>
          <p>
            If you need to modify the scope of work, extend, or adjust the
            service you requested, please contact us as soon as possible. We are
            happy to discuss how we can meet your needs and make necessary
            adjustments, but please be aware that adjustments may result in
            changes to the pricing or timeline of the service.
          </p>

          <h2>5. Exceptions</h2>
          <p>
            We reserve the right to deny cancellations or refunds in the
            following cases:
          </p>
          <ul>
            <li>
              <b>Fraudulent Activity:</b> If we believe that your cancellation
              or refund request is part of fraudulent activity or abuse, we may
              refuse to provide a refund or cancel your service.
            </li>
            <li>
              <b>Service Abuse:</b> If your use of our service violates our
              terms and conditions, we may deny the cancellation or refund
              request.
            </li>
          </ul>

          <h2>6. Changes to This Policy</h2>
          <p>
            We may update this Cancellation and Refund Policy from time to time.
            When we do, we will post the revised version on this page and update
            the effective date. It is your responsibility to review this page
            periodically to stay informed of any changes.
          </p>

          <h2>7. Contact Us</h2>
          <p>
            If you have any questions, concerns, or requests regarding this
            Cancellation and Refund Policy, please contact us at:
          </p>
          <ul>
            <li>
              <b>Email:</b>
              <a href="mailto:support@clotatechnology.com">
                support@clotatechnology.com
              </a>
            </li>
            <li>
              <b>Mobile:</b> <a href="tel:+91 9490384177 ">+91 9490384177 </a>
            </li>
            <li>
              <b>Address:</b> Shilpa Paradise 6-154/Y/103-120/401, Gothic
              Paranakuteer, Pragathi Nagar, KVRangareddy, Telangana ,India
              -500090.
            </li>
          </ul>
          {/* <h2>Disclaimer:</h2>
          <p>
            This Cancellation and Refund Policy is for informational purposes
            only and is not intended to provide legal advice. We recommend
            consulting with a legal professional to ensure that your
            cancellation and refund policies comply with all applicable laws and
            regulations.
          </p> */}
        </section>
      </div>
    </>
  );
};

export default CancellationAndRefunds;

import React from "react";
import NavBar from "../Navigation/NavBar";
import { Card } from "primereact/card";
import "./SqlServerTips.css";
import NewImg from "../../images/homeComingSoon911dba.png";
import Dealock from "../../images/Deadlock & locking.png";
import SQLServercounter from "../../images/Sql server Performance counter.png";
import Architecture from "../../images/SQL Server Application.png";
import TemDB from "../../images/Temp DB.png";
import Footer from "../Navigation/Footer";

function SqlServerTips() {
  return (
    <>
      <div className="sqlServerTipsParent">
        <div className="sqlServerTipsChild">
          <h1 className="text-center Text-Gradient">SQL Server Tips</h1>
          <div className="m-7 ">
            <Card className="m-3 ourSolutionCrd-1">
              <div className="tipContainer p-2 ">
                <div className="NewImg p-3">
                  <img
                    src={Dealock}
                    alt=""
                    style={{ height: "150px" }}
                    className="tipImage"
                  />
                </div>
                <div className="content ">
                  <h3>Deadlock & Locking Server</h3>
                  <p>
                    Deadlocks arise when two or more transactions block each
                    other by holding locks on resources the other transactions
                    need. SQL Server utilizes various locking mechanisms,
                    including shared, exclusive, and update locks, to control
                    access to data. The deadlock graph provides a visual
                    representation of the deadlock scenario, showing the
                    transactions involved and the resources they are waiting
                    for. To prevent deadlocks, developers can...{" "}
                    <span>
                      <a
                        href="https://blog.clotatech.com/2024/10/deadlock/"
                        target="_blank"
                        rel="noopener noreferrer">
                        read more
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </Card>
            <Card className="m-3 ourSolutionCrd-1">
              <div className="tipContainer p-2 mb-3">
                <div className="NewImg p-3">
                  <img
                    src={Architecture}
                    alt=""
                    style={{ height: "150px" }}
                    className="tipImage"
                  />
                </div>
                <div className="content">
                  <h3>SQL Server Architecture</h3>
                  <p>
                    SQL Server Database Engine: Responsible for data storage,
                    management, and security. It includes components like the
                    storage engine, query processor, and transaction manager.
                    SQL Server Analysis Services (SSAS): Provides tools for data
                    analysis, enabling complex calculations, aggregations, and
                    data mining. SQL Server Integration Services (SSIS):
                    Facilitates data extraction, transformation, and loading
                    (ETL) operations, allowing seamless data migration and
                    integration from various sources. SQL Server Reporting
                    Services (SSRS): Offers reporting capabilities, allowing
                    users to create, manage, and deliver reports based on data
                    stored in SQL Server...{" "}
                    <span>
                      <a
                        href="https://blog.clotatech.com/2024/10/sql-server-architecture-and-basics/"
                        target="_blank"
                        rel="noopener noreferrer">
                        read more
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </Card>
            <Card className="m-3 ourSolutionCrd-1">
              <div className="tipContainer p-2">
                <div className="NewImg p-3">
                  <img
                    src={SQLServercounter}
                    alt=""
                    style={{ height: "150px" }}
                    className="tipImage"
                  />
                </div>
                <div className="content">
                  <h3>SQL Server Performance Counter</h3>
                  <p>
                    Performance counters are instrumental in monitoring SQL
                    Server's health and performance. Key counters include:
                    Processor Time: Indicates CPU usage by SQL Server. Buffer
                    Cache Hit Ratio: Measures the efficiency of data retrieval
                    from memory versus disk. Disk Reads/Writes: Monitors I/O
                    operations, helping identify disk bottlenecks. Transactions
                    per Second: Reflects the system's throughput. Using tools
                    like SQL Server Performance Monitor (PerfMon) or SQL Server
                    Management Studio (SSMS) helps track these counters and make
                    informed optimization decisions...{" "}
                    <span>
                      <a
                        href="https://blog.clotatech.com/2024/10/buffer-cache-hit-ratio/"
                        target="_blank"
                        rel="noopener noreferrer">
                        read more
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </Card>
            <Card className="m-2 ourSolutionCrd-1">
              <div className="tipContainer p-2">
                <div className="NewImg p-3">
                  <img
                    src={TemDB}
                    alt=""
                    style={{ height: "150px" }}
                    className="tipImage"
                  />
                </div>
                <div className="content">
                  <h3>All About Temp DB</h3>
                  <p>
                    TempDB is a shared resource for all users and applications
                    in SQL Server. Its performance directly affects the overall
                    system performance. Key management strategies include:
                    Multiple Data Files: To reduce contention, especially in
                    high-concurrency environments, create multiple TempDB data
                    files. Monitor Space Usage: Regularly check TempDB size and
                    space utilization to avoid performance degradation. File
                    Growth Settings: Configure auto-growth settings
                    appropriately to prevent frequent growth events that can
                    impact performance...
                    <span>
                      <a
                        href="https://blog.clotatech.com/2024/10/all-about-tempdb-2/"
                        target="_blank"
                        rel="noopener noreferrer">
                        read more
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default SqlServerTips;

import React from "react";
import "../Cancellation And Refunds/CancellationAndRefunds.css";

const ShippingAndDelivery = () => {
  return (
    <>
      <div className="cancellation-refunds">
        <h1>Shipping and Delivery Policy</h1>
        <p>
          At 911DBA, we are dedicated to providing high-quality database
          emergency support and related services. As we primarily offer digital
          services, our Shipping and Delivery Policy outlines the process by
          which services are delivered to you once your purchase is confirmed.
        </p>
        <p>
          Please review the following details regarding how we deliver our
          services and what you can expect from us.
        </p>

        <section>
          <h2>1. Delivery of Services</h2>

          <h3>1.1 Emergency Database Support Services </h3>
          <ul>
            <li>
              <b>Service Delivery:</b>Once you request emergency database
              support, we will deliver our services remotely via telephone,
              email, or video conferencing, depending on the nature of the
              support and the agreement made.
            </li>
            <li>
              <b>Response Time:</b> After you contact us, our support team will
              acknowledge your request within 30 minutes and will begin working on the issue based on the
              priority level agreed upon.
            </li>
            <li>
              <b>Service Completion:</b> Emergency support will continue until
              the issue is resolved or until a solution is identified. If
              additional time is needed, we will inform you of the extended
              timeline and associated costs.
            </li>
          </ul>
          <h3>1.2 Consulting and Custom Projects</h3>

          <ul>
            <li>
              <b>Service Delivery: </b>For consulting services and custom
              database projects (such as database migration, optimization, or
              setup), we deliver the services digitally through online meetings,
              emails, and file sharing. We may also provide written reports,
              guides, or documentation via email or file-sharing platforms.
            </li>
            <li>
              <b>Project Timeline: </b>Upon confirmation of your order, we will
              provide an estimated timeline for project completion. We will work
              with you closely to meet agreed-upon deadlines and keep you
              updated on progress.
            </li>{" "}
            <li>
              <b>Service Adjustments: </b> If changes to the scope or timeline
              of the service are necessary, we will communicate these
              adjustments to you and get your approval before proceeding. .
            </li>
          </ul>
          <h3>1.3 Subscription-Based Services </h3>

          <ul>
            <li>
              <b>Access to Services:</b> You will be granted access to your
              account or dashboard where you can track service status, receive
              updates, and communicate with our support team as part of your
              subscription.
            </li>
            <li>
              <b>Service Delivery:</b>
              Subscription-based services, such as ongoing monitoring, database
              management, or regular consulting, are delivered through scheduled
              reports, regular meetings, and continuous monitoring of your
              database environment. Delivery is digital and ongoing as part of
              the service agreement.
            </li>
          </ul>
          <h3>Digital Products</h3>
          <p>
            If we offer any digital products, such as database management tools,
            templates, or guides:{" "}
          </p>
          <ul>
            <li>
              <b>Delivery Method:</b> Digital products are delivered
              electronically via email or downloadable links sent to your
              registered email address upon successful payment.{" "}
            </li>
            <li>
              <b>Download Links:</b> If you purchase a digital product, a
              download link will be provided to you immediately or within
              30 minutes after your purchase.
            </li>
          </ul>

          <h2>2. Delivery Times</h2>
          <p>
            As most of our services are delivered remotely and digitally, the
            delivery times depend on the specific service you request:
          </p>

          <ul>
            <li>
              <b>Emergency Support: </b> Services are typically initiated as
              soon as possible upon receiving your request, with a response time
              of 30 minutes.
            </li>
            <li>
              <b>Consulting and Custom Projects:</b> Once we receive the
              necessary information to start a project, we will provide you with
              an estimated delivery timeline. Custom projects are generally
              delivered within 30 minutes, depending
              on their complexity.
            </li>{" "}
            <li>
              <b>Subscription-Based Services:</b>
              Ongoing services are delivered as part of the subscription
              agreement, with regular updates or reports delivered on a
              recurring basis (e.g., weekly, monthly).
            </li>
          </ul>

          <h2>3. Accessing Your Services</h2>
          <p>
            All services are provided digitally and can be accessed remotely by
            you. You will receive detailed instructions on how to access and use
            our services once your order is confirmed. We use secure channels,
            such as encrypted emails, video conferencing, and password-protected
            portals, to ensure that your data remains safe.
          </p>
          <p>
            If you have any issues accessing the services, please contact our
            support team, and we will assist you promptly.{" "}
          </p>

          <h2>4. Service Delays</h2>
          <p>
            While we strive to provide timely delivery of all services, there
            may be circumstances beyond our control that could cause delays.
            These include:
          </p>
          <ul>
            <li>
              <b>Client-Specific Delays:</b> If we require information or access
              from your end (such as database credentials or system access) and
              experience delays in receiving them.{" "}
            </li>
            <li>
              <b>Technical Issues:</b> Rare technical difficulties on our end,
              including but not limited to server outages, network issues, or
              other service interruptions.
            </li>
          </ul>
          <p>
            In the event of a delay, we will inform you as soon as possible and
            provide a new estimated delivery time.{" "}
          </p>

          <h2>5. Shipping Costs</h2>
          <p>
            Since our services are delivered digitally, there are no physical
            shipping costs associated with your order. All service delivery is
            remote, and no physical shipping fees will apply.
          </p>

          <h2>6. Service Satisfaction and Disputes</h2>
          <p>
            We are committed to delivering high-quality services. If for any
            reason you are not satisfied with the service you receive:
          </p>
          <ul>
            <li>
              <b>Contact Us:</b>Please reach out to our support team immediately
              at{" "}
              <a href="mailto:support@clotatechnology.com">
                support@clotatechnology.com
              </a>{" "}
              or <a href="tel:+91 9490384177 ">+91 9490384177 </a>. We will work
              with you to resolve any issues or concerns you have with the
              service.{" "}
            </li>
            <li>
              <b>Service Adjustments:</b> If the service provided does not meet
              your expectations, we will assess the situation and may offer a
              revision, extension, or in some cases, a partial refund based on
              our Cancellation and Refund Policy.{" "}
            </li>
          </ul>
          <h2>7. Changes to This Policy</h2>
          <p>
            We may update our <b>Shipping and Delivery Policy</b> from time to
            time. When changes are made, we will update the date of the policy
            at the top of this page. We encourage you to review this policy
            periodically to stay informed about how we deliver our services to
            you.
          </p>
          <h2>8. Contact Us</h2>
          <p>
            If you have any questions about the delivery of our services, need
            help accessing your purchased services, or have any concerns about
            our Shipping and Delivery Policy, please don’t hesitate to contact
            us:{" "}
          </p>
          <ul>
            <li>
              <b>Email:</b>
              <a href="mailto:support@clotatechnology.com">
                support@clotatechnology.com
              </a>
            </li>
            <li>
              <b>Mobile:</b> <a href="tel:+91 9490384177 ">+91 9490384177 </a>
            </li>
            <li>
              <b>Address:</b> Shilpa Paradise 6-154/Y/103-120/401, Gothic
              Paranakuteer, Pragathi Nagar, KVRangareddy, Telangana ,India
              -500090.
            </li>
          </ul>
          {/* <h2>Disclaimer:</h2>
          <p>
            This Shipping and Delivery Policy is for informational purposes only and is not intended to provide legal advice. We recommend consulting with a legal professional to ensure that your shipping and delivery policies comply with all applicable laws. 
          </p> */}
        </section>
      </div>
    </>
  );
};

export default ShippingAndDelivery;

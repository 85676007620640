import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const TransationResponses = () => {
  const navigate = useNavigate();
  const transactionID = new URLSearchParams(window.location.search).get(
    "transactionID"
  );

  useEffect(() => {
    if (transactionID) {
      axios
        .get(`/redirect-url/${transactionID}`)
        .then((response) => {
          const { status, paymentStatus } = response.data;
          // Handle the navigation based on payment status
          if (status === "Payment Successful") {
            navigate("/success");
          } else if (status === "Payment Error") {
            navigate("/error");
          } else {
            navigate("/pending");
          }
          console.log(paymentStatus);
        })
        .catch((error) => {
          console.error("Error verifying payment:", error);
          navigate("/error");
        });
    }
  }, [transactionID, navigate]);

  return <div>Loading...</div>;
};

export default TransationResponses;

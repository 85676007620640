import { useState } from "react";
import "./App.css";
import PageRouter from "./Modules/PageRouter/PageRouter";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function App() {
  const [clientDetail, setClientDetail] = useState(
    // "AQuYQwo3x9vK2nFdiQKAplNgGwQZ4AFOoVs2bh3VDhCvwB-H8yhFecPMcQEgYJH78WYfwVmB0ygUHQZ6"
    "Af5wAv4k_TkyRdXor1tEZIqnLWA2PDTnV-q529lKUcNJ9ViacW_CGcyUkdDJJ-MWUnCK4UFTE_7UoTNb"
  );
  return (
    <PayPalScriptProvider
      options={{
        "client-id": clientDetail,
      }}>
      <PageRouter />
    </PayPalScriptProvider>
  );
}

export default App;

import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

function FilesManagement() {
  const [clientDetails, setClientDetails] = useState("");

  async function fetGeneralTimeSlots() {
    const response = await fetch("/generalTimeSlots", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    setClientDetails(result);
    // setSelectedTimeslot(result.slotTimes[0]);
  }

  useEffect(() => {
    fetGeneralTimeSlots();
  }, []);

  return (
    <div>
      <Card className="m-5">
        <div className="firstdivClients">
          <h2 className="">Manage Files</h2>
          <div>
            <Button label="Add" />
          </div>
        </div>
        <Divider />
        <DataTable
          tableStyle={{ minWidth: "50rem" }}
          // value={clientDetails}
          size="small"
          paginator
          rows={10}
          showGridlines
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          removableSort
          scrollable
          scrollHeight="250px"
          className="FilterTable allDataTableThCenterCommon m-2"
          emptyMessage="No Data found.">
          <Column
            header="ClientID"
            field="fullName"
            frozen
            filter
            className="allTableTDCenterCommon"
          />
          <Column
            header="ClientName"
            field="ClientName"
            className="allTableTDCenterCommon"
            sortable
            filter
          />{" "}
          <Column
            header="ClientEmailID"
            field="ClientEmailID"
            className="allTableTDCenterCommon"
            sortable
            filter
          />{" "}
          <Column
            header="ClientAddress"
            field="ClientAddress"
            className="allTableTDCenterCommon"
            sortable
            filter
          />{" "}
          <Column
            header="ClientContactNumber"
            field="ClientContactNumber"
            className="allTableTDCenterCommon"
            sortable
            filter
          />
          <Column
            // header="Action"
            field="ClientContactNumber"
            className="allTableTDCenterCommon"
          />
        </DataTable>
      </Card>
    </div>
  );
}

export default FilesManagement;

import React, { useEffect, useState } from "react";
import NavbarCustomerAdmin from "../CustomerModules/NavbarCustomerAdmin";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import { RiLockPasswordFill, RiRefreshLine } from "react-icons/ri";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import "./LoginPage.css";
import LoginLogo from "../../images/CustomerImages/login911.png";
import Logo from "../../images/logo 911.png";

function LoginPage() {
  const jwtTokenn = uuidv4();
  const [userName, setUserName] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const randomString = Math.random().toString(36).slice(8);
  const [captcha, setCaptcha] = useState(randomString);
  const [text, setText] = useState("");
  const [valid, setValid] = useState(false);
  const [clicked, setClicked] = useState(false);
  function refreshCaptcha() {
    setCaptcha(Math.random().toString(36).slice(8));
  }

  const MatchCaptcha = (event) => {
    setClicked(true);
    event.preventDefault();
    if (text === captcha) {
      setValid(true);
      handleSubmit(event);
      return;
    } else {
      setValid(false);
      refreshCaptcha();
      return;
    }
  };
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch("/authenticate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName: userName.toLowerCase(),
          userPassword,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        const loginTimeResponse = await fetch("/loginTimeUser", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uniqueID: data.user.uniqueID,
            login: new Date(),
          }),
        });

        if (loginTimeResponse.ok) {
          const oldToken = sessionStorage.getItem("jwtToken");

          if (oldToken) {
            const expireToken = await fetch("/expire-token", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: oldToken,
              }),
            });

            if (expireToken.ok) {
              sessionStorage.removeItem("jwtToken");
              sessionStorage.clear();
            } else {
              console.error("Failed to expire token");
            }
          }

          //example code
          // if (sessionStorage.getItem("employeeID")) {
          //   sessionStorage.removeItem("employeeID");
          // }

          sessionStorage.setItem("jwtToken", data.jwtToken);
          sessionStorage.setItem("uniqueID", data.user.uniqueID);
          sessionStorage.setItem("roleName", data.user.role[0].roleName);

          setIsAuthenticated(true);
        } else {
          setError("Failed to save login time");
        }
      } else {
        setError("Incorrect username or password");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/enduser", {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        });

        if (response.ok) {
          const roleName = sessionStorage.getItem("roleName");
          const uniqueID = sessionStorage.getItem("uniqueID");
          const jwtToken = sessionStorage.getItem("jwtToken");
          if (roleName === "Client") {
            sessionStorage.setItem("clientUniqueID", uniqueID);
          } else if (roleName === "Admin") {
            sessionStorage.setItem("adminUniqueID", uniqueID);
          } else {
            navigate(`/error`);
          }

          navigate(`/LoginUser/${jwtTokenn}/`);
        } else {
          console.log("Error: ", response.status);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <div className="loginPageParent">
        {/* <nav>
          <Link to="/login">
            <img className="loginNav-icon" src={LoginLogo} alt="" />
          </Link>
        </nav> */}
        <div className="grid">
          <div className="col-12 lg:col-6">
            <Card className="loginLeftSideCard">
              <div className="topImg">
                <img
                  src={Logo}
                  alt="topLogo"
                  height={100}
                  className="logo-Img"
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                />
                <h1>Welcome Back</h1>
              </div>
              <div className="login-left-img">
                <img src={LoginLogo} alt="middleLogo" className="middleLogo" />
              </div>
            </Card>
          </div>
          <div className="col-12 lg:col-6">
            <Card className="loginCardDiv">
              <div className="loginCard ">
                <form onSubmit={MatchCaptcha}>
                  {/* <form onSubmit={handleSubmit}> */}
                  <table>
                    <tr>
                      <td colSpan={2}>
                        <h4 className="heading-for-login">Login To Continue</h4>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <label htmlFor="UserName" className="grey-color">
                          Username
                          <span className="starForMandatoryInInputFields">
                            {" "}
                            *
                          </span>
                        </label>
                      </td>
                      <td className="relative">
                        <FaRegUserCircle
                          style={{
                            position: "absolute",
                            top: "15px",
                            left: "10px",
                          }}
                          className="svgIconLogin"
                        />
                        <InputText
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                          //   keyfilter="email"
                          autoComplete="off"
                          className="customInputField"
                          placeholder="Enter Registered EmailID"
                          required
                          autoFocus
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="UserName" className="grey-color">
                          Password{" "}
                          <span className="starForMandatoryInInputFields">
                            *
                          </span>
                        </label>
                      </td>
                      <td className="relative">
                        <RiLockPasswordFill
                          style={{
                            position: "absolute",
                            top: "15px",
                            left: "10px",
                            zIndex: "2",
                          }}
                          className="gsvgIconLogin"
                          placeholder="Enter Password"
                        />
                        <Password
                          value={userPassword}
                          onChange={(e) => setUserPassword(e.target.value)}
                          toggleMask
                          required
                          className="customInputField"
                          feedback={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ position: "relative" }}
                        className="textCenter">
                        <div
                          style={{
                            marginLeft: "5px",
                            marginRight: "10px",
                            display: "inline-block",
                          }}
                          className="Captcha">
                          {captcha}
                        </div>
                        <RiRefreshLine
                          onClick={refreshCaptcha}
                          style={{
                            fontSize: "1.5rem",
                            position: "absolute",
                            top: "9px",
                            cursor: "pointer",
                            color: "black",
                          }}
                          pointerEvents={valid ? "none" : ""}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {clicked && !valid && (
                          <p cl className="invalid-text textCenter">
                            Invalid Captcha
                          </p>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <div className="form-control">
                        <InputText
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Enter Text Above"
                          disabled={valid}
                          value={text}
                          onChange={(e) => setText(e.target.value)}
                          required
                        />
                      </div>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <Link to="/forgetPassword" className="text-center">
                          Forgot Password?
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <Button
                          type="submit"
                          loading={isLoading}
                          label="submit"
                          raised
                          title="Fill All The InputFields"
                          disabled={!text || !userName || !userPassword}
                          // disabled={!userName || !userPassword}
                          className="roundad"
                        />
                      </td>
                    </tr>
                  </table>
                </form>
                {error && (
                  <p
                    className="text-center text-danger"
                    style={{ height: "20px", color: "red" }}>
                    {error}
                  </p>
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;

import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

function ManageIncidents() {
  const [allClientIncidents, setAllClientIncidents] = useState([]);

  async function fetchAllClientIncidents() {
    const response = await fetch("/allClientIncidents", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
     const result = await response.json();
     if (Array.isArray(result) && result.length > 0) {
       setAllClientIncidents(result);
     } else {
       setAllClientIncidents([]);
     }
  }

  useEffect(() => {
    fetchAllClientIncidents();
  }, []);

  return (
    <div>
      <Card className="m-5">
        <div className="firstdivClients">
          <h2 className="">Manage Incidents</h2>
          <div>
            <Button label="Add" />
          </div>
        </div>
        <Divider />
        <DataTable
          tableStyle={{ minWidth: "50rem" }}
          value={allClientIncidents}
          size="small"
          paginator
          rows={10}
          showGridlines
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          removableSort
          scrollable
          scrollHeight="250px"
          className="FilterTable allDataTableThCenterCommon m-2"
          emptyMessage="No Data found.">
          <Column
            header="caseID"
            field="caseID"
            frozen
            filter
            className="allTableTDCenterCommon"
          />
          <Column
            header="ClientName"
            field="clientName"
            className="allTableTDCenterCommon"
            sortable
            filter
          />
          <Column
            header="DescriptionOfWork	"
            field="descriptionOfWork"
            className="allTableTDCenterCommon"
            sortable
            filter
          />
          <Column
            header="Category"
            field="categoryName"
            className="allTableTDCenterCommon"
            sortable
            filter
          />
          <Column
            header="Status"
            field="status"
            className="allTableTDCenterCommon"
            sortable
            filter
          />
          <Column
            header="DueDate"
            field="DueDate"
            className="allTableTDCenterCommon"
          />
        </DataTable>
      </Card>
    </div>
  );
}


export default ManageIncidents
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const jwtToken = sessionStorage.getItem("jwtToken");
  const isAuthenticated = !!jwtToken;

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export { ProtectedRoute };

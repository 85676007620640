import React from 'react'
import NavBar from '../Navigation/NavBar';
import Footer from '../Navigation/Footer';

function FourZeroFourPage() {
  return (
    <>
      <NavBar />
      <div>FourZeroFourPage</div>
      <Footer />
    </>
  );
}

export default FourZeroFourPage;
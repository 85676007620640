import React, { useEffect, useRef, useState } from "react";
import "./NavbarCustomer.css";
import "./FooterCustomer.css";
import { Link } from "react-router-dom";
import { Outlet, NavLink, useLocation, useNavigate } from "react-router-dom";
import { BsFillShieldLockFill } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid";
import { IoMdClose } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdAddCall, MdTipsAndUpdates } from "react-icons/md";
import Logo911 from "../../images/logo 911.png";
import { TimeoutLogic } from "../SessionTimeout/TimeOutLogic";

import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import "./FooterCustomer.css";
import { IoLogIn } from "react-icons/io5";

function NavbarCustomerAdmin() {
  const dropdownRef = useRef(null);
  const role = sessionStorage.getItem("roleName");
  const uniqueID = sessionStorage.getItem("uniqueID");
  const jwtToken = uuidv4();
  // const [isOpen, setIsOpen] = useState(false);
  const [isClientsOpen, setIsClientsOpen] = useState(false);
  const [isScheduledCallsOpen, setIsScheduledCallsOpen] = useState(false);
  const [isIncidentsOpen, setIsIncidentsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select an option");
  const [navOpen, setNavOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const Today = new Date();
  const CurrentYear = Today.getFullYear();
  const [openTerms, setOpenTerms] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [openLegalHelp, setOpenLegalHelp] = useState(false);
  //  const navigate = useNavigate();

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const openCallsDropdown = (e) => {
    setIsScheduledCallsOpen(true);
  };
  const closeCallsDropdown = (e) => {
    setIsScheduledCallsOpen(false);
  };

  const handleOptionCallsClick = (option) => {
    setSelectedOption(option);
    closeCallsDropdown();
  };

  const openIncidentsdown = (e) => {
    setIsIncidentsOpen(true);
  };
  const closeIncidentsDropdown = (e) => {
    setIsIncidentsOpen(false);
  };

  const handleOptionIncidentsClick = (option) => {
    setSelectedOption(option);
    closeIncidentsDropdown();
  };

  const generatePath = (base) => `/LoginUser/${base}/${jwtToken}`;

  const handleListItemClick = (base) => {
    sessionStorage.setItem("selectedEmployeeID", uniqueID);
    const route = generatePath(base);
    navigate(route);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeCallsDropdown();
      closeIncidentsDropdown();
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const location = useLocation();
  const currentPath = location.pathname;
  const pathToMatch = "/";
  const shouldAddClass = currentPath === pathToMatch;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  function handleClick() {
    setNavOpen(!navOpen);
  }
  const navigate = useNavigate();
  const handleNavigation = () => {
    sessionStorage.clear();
    navigate("/login");
    setNavOpen(false);
  };

  return (
    <>
      <nav className="navBarCustomer">
        <Link to={`/LoginUser/Dashboard/${jwtToken}`}>
          <img className="cutomerNav-icon" src={Logo911} alt="" />
        </Link>
        <ul ref={dropdownRef} className={navOpen ? "opencutomerNav" : ""}>
          <li className="nav-items">
            <NavLink
              activeClassName="active"
              exact
              to={`/LoginUser/Dashboard/${jwtToken}`}
              onClick={() => {
                setNavOpen(false);
              }}>
              Dashboard
            </NavLink>
          </li>
          {/* --Clients --Manage Clients ==> manage Client Services --Incidents
          --==> Incident Owners, Service Category, Files Management --Scheduled
          Calls --TimeSlots, FreeCalls, Standard Calls, Premium Calls */}
          {role === "Admin" && (
            <>
              <li className="nav-items">
                <NavLink
                  to={`/LoginUser/Clients/${jwtToken}`}
                  exact
                  onClick={() => {
                    setNavOpen(false);
                  }}>
                  Clients
                </NavLink>
              </li>

              <li className="dropdownparent">
                <div
                  id="services"
                  style={{ cursor: "pointer" }}
                  onMouseEnter={openIncidentsdown}
                  onMouseLeave={closeIncidentsDropdown}>
                  Incidents ▼
                </div>
                {isIncidentsOpen && (
                  <div
                    className="dropdown-content"
                    onMouseEnter={openIncidentsdown}
                    onMouseLeave={closeIncidentsDropdown}>
                    <Link
                      to={`/LoginUser/ClientServices/${jwtToken}`}
                      className="dd-links"
                      onClick={() => {
                        handleOptionIncidentsClick("Option 1");
                        setNavOpen(false);
                      }}>
                      <div className="icon-div">
                        <MdTipsAndUpdates />
                      </div>
                      Manage Incidents
                    </Link>
                    <Link
                      to={`/LoginUser/IncidentCategories/${jwtToken}`}
                      className="dd-links"
                      onClick={() => {
                        handleOptionIncidentsClick("Option 1");
                        setNavOpen(false);
                      }}>
                      <div className="icon-div">
                        <MdTipsAndUpdates />
                      </div>
                      Incidents Category
                    </Link>
                    <Link
                      to={`/LoginUser/ManageFiles/${jwtToken}`}
                      className="dd-links"
                      onClick={() => {
                        handleOptionIncidentsClick("Option 2");
                        setNavOpen(false);
                      }}>
                      <div className="icon-div">
                        <BsFillShieldLockFill />
                      </div>
                      Files Management
                    </Link>
                  </div>
                )}
              </li>
              <li className="nav-items">
                <NavLink
                  to={`/LoginUser/ManageCalls/${jwtToken}`}
                  exact
                  onClick={() => {
                    setNavOpen(false);
                  }}>
                  Scheduled Calls
                </NavLink>
              </li>
            </>
          )}

          {role === "Client" && (
            <>
              <li className="nav-items">
                <NavLink
                  to={`/LoginUser/MyRequests/${jwtToken}`}
                  exact
                  onClick={() => {
                    setNavOpen(false);
                  }}>
                  My Requests
                </NavLink>
              </li>
              <li className="nav-items">
                <NavLink
                  to={`/LoginUser/Aboutus/${jwtToken}`}
                  exact
                  onClick={() => {
                    setNavOpen(false);
                  }}>
                  About Us
                </NavLink>
              </li>
              <li className="nav-items">
                <NavLink
                  to={`/LoginUser/Contactus/${jwtToken}`}
                  onClick={() => {
                    setNavOpen(false);
                  }}>
                  Contact
                </NavLink>{" "}
              </li>
            </>
          )}
        </ul>
        <div className="contact-section-cutomerNav">
          <button
            onClick={handleNavigation}
            className={`btn ${shouldAddClass ? "border-right" : ""}`}>
            <div className="contact-icon">
              <IoLogIn />
            </div>
            <div className="contact-text">LogOut</div>
          </button>

          {isDialogOpen && (
            <div className="dialog-overlay" onClick={handleDialogClose}>
              <div className="dialog-box" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={handleDialogClose}>
                  ×
                </button>
                <div className="dialog-content">
                  <MdAddCall style={{ fontSize: "2rem", color: "#4CAF50" }} />
                  <p
                    style={{
                      margin: "10px 0",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}>
                    +91 9490384177
                  </p>
                  <a href="tel:+919490384177" className="call-button">
                    Dial Now
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="hamburger" onClick={handleClick}>
          {navOpen ? <IoMdClose /> : <RxHamburgerMenu />}
        </div>
      </nav>
      <main style={{ backgroundColor: "#f7f7f7f7" }}>
        <Outlet />
      </main>
      <div className="Footer">
        <div className="footer-abtUs">
          <ul>
            <li className="footer-point-headings">ABOUT US</li>
            <li className="footer-list-items">
              Over a decade of experience and implementing best solutions in
              Database Technologies and Web Development. It's our passion for
              technologies which lead us here.
              <Link
                to={`/LoginUser/Aboutus/${jwtToken}`}
                className="link-footer">
                Read More{" "}
              </Link>
            </li>
            <li className="icons">
              <div className="social-icons">
                <a href="https://www.instagram.com/ClotaTech" target="_clota">
                  <FaInstagram />
                </a>
              </div>
              <div className="social-icons">
                <a href="https://www.facebook.com/ClotaTech" target="_clota">
                  <FaFacebookF />
                </a>
              </div>
              <div className="social-icons">
                <a href="https://twitter.com/ClotaTech" target="_clota">
                  <FaTwitter />
                </a>
              </div>
              <div className="social-icons">
                <a
                  href="https://www.linkedin.com/company/clota-tech/"
                  target="_clota">
                  <FaLinkedin />
                </a>
              </div>
              <div className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=919490384177&amp;text=Hi, i'm enquiriying through your Site"
                  target="_clota">
                  <FaWhatsapp />
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div className="footer-srvcs">
          <ul className="list-margin">
            <li className="footer-point-headings"> SERVICES</li>
            <li className="footer-list-items p-1">
              <Link
                className="hover-effect-links"
                to={`/LoginUser/SQLTips/${jwtToken}`}>
                SQL Tips
              </Link>
            </li>
            <li className="footer-list-items p-1">
              <Link
                className="hover-effect-links"
                to={`/LoginUser/OurProducts/${jwtToken}`}>
                Our Products
              </Link>
            </li>
          </ul>
        </div>{" "}
        <div className="footer-srvcs">
          <ul className="list-margin">
            <li className="footer-point-headings">
              {" "}
              <a
                className="hover-effect-links"
                onClick={() => {
                  navigate(`/LoginUser/Contactus/${jwtToken}`);
                }}>
                Help Center
              </a>
            </li>
            <li className="footer-list-items p-1">
              <a
                className="hover-effect-links"
                onClick={() => {
                  navigate(`/LoginUser/TermsAndConditions/${jwtToken}`);
                }}>
                Terms Conditions
              </a>
            </li>
            <li className="footer-list-items p-1">
              <a
                className="hover-effect-links"
                onClick={() => {
                  navigate(`/LoginUser/PrivacyPolicies/${jwtToken}`);
                }}>
                Privacy Policy
              </a>
            </li>

            <li className="footer-list-items p-1">
              <a
                className="hover-effect-links"
                onClick={() => {
                  navigate(`/LoginUser/RefundPolicies/${jwtToken}`);
                }}>
                Cancellation And Refunds
              </a>
            </li>
            {/* <li className="footer-list-items p-1">
              <a
                className="hover-effect-links"
                onClick={() => {
                  navigate("/PrivacyPolic");
                }}>
                Shipping And Delivery
              </a>
            </li> */}
            <li className="footer-list-items p-1">
              <a
                className="hover-effect-links"
                // target="_blank"

                onClick={() => {
                  setOpenTerms(false);
                  setOpenHelp(false);
                  setOpenLegalHelp(false);
                  navigate(`/LoginUser/Contactus/${jwtToken}`);
                }}>
                Help Center
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-cntctUs">
          <ul>
            <li className="footer-point-headings"> CONTACT US</li>
            <li className="footer-list-items">
              {" "}
              401 Plot # 103, Shilpa Paradise,{" "}
            </li>
            <li className="footer-list-items">Pragathi Nagar Hyderabad, </li>
            <li className="footer-list-items">Telangana, India 500090</li>
            <li className="footer-list-items mt-2">
              <a className="hover-effect-links" href="tel:+91 9490384177">
                +91 9490384177,
              </a>
              <a className="hover-effect-links ml-2" href="tel:+91 9573706658">
                +91 9573706658
              </a>
            </li>

            <li className="footer-list-items mt-2">
              <a
                className="hover-effect-links"
                href="mailto:support@clotatechnology.com">
                support@clotatechnology.com
              </a>
            </li>
          </ul>
        </div>
        {/* Social Icons Section (Last Element) */}
        <div className="footer-social-icons">
          <div className="social-icons">
            <a href="https://www.instagram.com/ClotaTech" target="_clota">
              <FaInstagram />
            </a>
          </div>
          <div className="social-icons">
            <a href="https://www.facebook.com/ClotaTech" target="_clota">
              <FaFacebookF />
            </a>
          </div>
          <div className="social-icons">
            <a href="https://twitter.com/ClotaTech" target="_clota">
              <FaTwitter />
            </a>
          </div>
          <div className="social-icons">
            <a
              href="https://www.linkedin.com/company/clota-tech/"
              target="_clota">
              <FaLinkedin />
            </a>
          </div>
          <div className="social-icons">
            <a
              href="https://api.whatsapp.com/send?phone=919490384177"
              target="_clota">
              <FaWhatsapp />
            </a>
          </div>
        </div>
        <div className="copyright">
          <Link className="link-footer" to="/">
            911dba
          </Link>{" "}
          911DBA Copyright © {CurrentYear} All rights reserved. Powered by{" "}
          <a
            className="link-footer"
            target="_blank"
            href="https://Clotatech.com">
            Clotatech.com
          </a>
        </div>
      </div>
      <TimeoutLogic />
    </>
  );
}

export default NavbarCustomerAdmin;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../HomePage/Home";
import AboutPage from "../About/AboutPage";
import ContacusPage from "../ContactUs/ContacusPage";
import SqlServerTips from "../DropdownPages/SqlServerTips";
import OurProducts from "../DropdownPages/OurProducts";
import FourZeroFourPage from "../ErrorPages/FourZeroFourPage";
import NavBar from "../Navigation/NavBar";
import Footer from "../Navigation/Footer";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import CancellationAndRefunds from "../Cancellation And Refunds/CancellationAndRefunds";
import PricingPage from "../Pricing/Pricing";
import CheckoutPage from "../Pricing/Payments/CheckoutPage";
import StandardCall from "../Pricing/CallScheduling/StandardCall";
import FreeCall from "../Pricing/CallScheduling/FreeCall";
import LoginPage from "../Login/LoginPage";
import NavbarCustomerAdmin from "../CustomerModules/NavbarCustomerAdmin";
import Dashboard from "../CustomerModules/CommonDashboard/Dashboard";
import { ProtectedRoute } from "../ProtectedRoutes/ProtectedRoute";

import CustRequestAndServices from "../CustomerModules/RequestsAndServices/CustRequestAndServices";
import PremiumCall from "../Pricing/CallScheduling/PremiumCall";
import ManageClients from "../CustomerModules/ClientsAdmin/ManageClients";
import ManageIncidents from "../CustomerModules/IncidentsAdmin/ManageIncidents";
import IncidentCategories from "../CustomerModules/IncidentsAdmin/IncidentCategories";
import FilesManagement from "../CustomerModules/IncidentsAdmin/FilesManagement";
import ManageCalls from "../CustomerModules/CallsAdmin/ManageCalls";
import ShippingAndDelivery from "../ShippingDelivery/ShippingAndDelivery";
import PhonepayTesting from "../../PhonepayTesting";
import { FailedTransation, InternalServerError, PendingTransation, SuccessTransation } from "../../PhonepayTransations/StatusCodes/PhonepeTransationStatues";
import TransationResponses from "../../PhonepayTransations/TransationResponses";

function PageRouter() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<FourZeroFourPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/payment-success" element={<SuccessTransation />} />
        <Route path="/payment-failed" element={<FailedTransation />} />
        <Route path="/payment-pending" element={<PendingTransation />} />{" "}
        <Route path="/interServerError" element={<InternalServerError />} />
        <Route path="/redirect-url" element={<TransationResponses />} />
        <Route path="/paymentTesting" element={<PhonepayTesting />} />
        {/* Common Pages */}
        <Route path="/" element={<NavBar />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/aboutus" element={<NavBar />}>
          <Route index element={<AboutPage />} />
        </Route>
        <Route path="/contactus" element={<NavBar />}>
          <Route index element={<ContacusPage />} />
        </Route>
        <Route path="/SqlServerTips" element={<NavBar />}>
          <Route index element={<SqlServerTips />} />
        </Route>
        <Route path="/OurProducts" element={<NavBar />}>
          <Route index element={<OurProducts />} />
        </Route>
        <Route path="/PrivacyPolicy" element={<NavBar />}>
          <Route index element={<PrivacyPolicy />} />
        </Route>
        <Route path="/TermsAndConditions" element={<NavBar />}>
          <Route index element={<TermsAndConditions />} />
        </Route>
        <Route path="/ShippingAndDelivery" element={<NavBar />}>
          <Route index element={<ShippingAndDelivery />} />
        </Route>
        <Route path="/CheckOut" element={<NavBar />}>
          <Route index element={<CheckoutPage />} />
        </Route>
        <Route path="/CheckoutAndPayment" element={<NavBar />}>
          <Route index element={<StandardCall />} />
        </Route>
        <Route path="/ScheduleAPremiumCall" element={<NavBar />}>
          <Route index element={<PremiumCall />} />
        </Route>
        <Route path="/ScheduleAFreeCall" element={<NavBar />}>
          <Route index element={<FreeCall />} />
        </Route>
        <Route path="/CancellationAndRefunds" element={<NavBar />}>
          <Route index element={<CancellationAndRefunds />} />
        </Route>
        <Route path="/PricingPage" element={<NavBar />}>
          <Route index element={<PricingPage />} />
        </Route>
        {/* <Route path="*" element={<ComingSoonError />} /> */}
        <Route element={<ProtectedRoute />}>
          <Route
            path="/LoginUser/:jwtToken/*"
            element={<NavbarCustomerAdmin />}>
            <Route index element={<Dashboard />} />
          </Route>
          <Route
            path="/LoginUser/Dashboard/:jwtToken/*"
            element={<NavbarCustomerAdmin />}>
            <Route index element={<Dashboard />} />
          </Route>
          {/* Customer Footer Paths */}
          <Route
            path="/LoginUser/TermsAndConditions/:jwtToken/*"
            element={<NavbarCustomerAdmin />}>
            <Route index element={<TermsAndConditions />} />
          </Route>
          <Route
            path="/LoginUser/PrivacyPolicies/:jwtToken/*"
            element={<NavbarCustomerAdmin />}>
            <Route index element={<PrivacyPolicy />} />
          </Route>
          <Route
            path="/LoginUser/RefundPolicies/:jwtToken/*"
            element={<NavbarCustomerAdmin />}>
            <Route index element={<CancellationAndRefunds />} />
          </Route>
          <Route
            path="/LoginUser/Contactus/:jwtToken/*"
            element={<NavbarCustomerAdmin />}>
            <Route index element={<ContacusPage />} />
          </Route>
          {/* Customer Footer Paths */}
          {/*Common Customer Navbar Paths*/}
          <Route
            path="/LoginUser/Aboutus/:jwtToken/*"
            element={<NavbarCustomerAdmin />}>
            <Route index element={<AboutPage />} />
          </Route>
          <Route
            path="/LoginUser/SQLTips/:jwtToken/*"
            element={<NavbarCustomerAdmin />}>
            <Route index element={<SqlServerTips />} />
          </Route>
          <Route
            path="/LoginUser/OurProducts/:jwtToken/*"
            element={<NavbarCustomerAdmin />}>
            <Route index element={<OurProducts />} />
          </Route>
          {/*Common Customer Navbar Paths*/}
          {/* Admin Paths */}

          <Route
            path="/LoginUser/Clients/:jwtToken/*"
            element={<NavbarCustomerAdmin />}>
            <Route index element={<ManageClients />} />
          </Route>
          <Route
            path="/LoginUser/ClientServices/:jwtToken/*"
            element={<NavbarCustomerAdmin />}>
            <Route index element={<ManageIncidents />} />
          </Route>

          <Route
            path="/LoginUser/IncidentCategories/:jwtToken/*"
            element={<NavbarCustomerAdmin />}>
            <Route index element={<IncidentCategories />} />
          </Route>
          <Route
            path="/LoginUser/ManageFiles/:jwtToken/*"
            element={<NavbarCustomerAdmin />}>
            <Route index element={<FilesManagement />} />
          </Route>
          <Route
            path="/LoginUser/ManageCalls/:jwtToken/*"
            element={<NavbarCustomerAdmin />}>
            <Route index element={<ManageCalls />} />
          </Route>

          {/* Admin Paths */}

          {/* Client Paths */}
          <Route
            path="/LoginUser/MyRequests/:jwtToken/*"
            element={<NavbarCustomerAdmin />}>
            <Route index element={<CustRequestAndServices />} />
          </Route>
          {/* Client Paths */}
        </Route>
      </Routes>
    </Router>
  );
}

export default PageRouter;
